// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tariffsApplicant_container__ALB\\+R {\n    margin-top: 3rem;\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    gap: 4rem;\n}\n\n.tariffsApplicant_cardsBlock__TghGi {\n    display: flex;\n    flex-flow: row;\n    gap: 5rem;\n}\n\n.tariffsApplicant_headlineBlock__46H5x {\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    font-weight: 600;\n    font-size: 0.7rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/tariffs/tariffsApplicant/tariffsApplicant.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,cAAc;IACd,SAAS;AACb;;AAEA;IACI,+BAA+B;IAC/B,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".container {\n    margin-top: 3rem;\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    gap: 4rem;\n}\n\n.cardsBlock {\n    display: flex;\n    flex-flow: row;\n    gap: 5rem;\n}\n\n.headlineBlock {\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    font-weight: 600;\n    font-size: 0.7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "tariffsApplicant_container__ALB+R",
	"cardsBlock": "tariffsApplicant_cardsBlock__TghGi",
	"headlineBlock": "tariffsApplicant_headlineBlock__46H5x"
};
export default ___CSS_LOADER_EXPORT___;
