// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gradesBlock_container__3J2Wz {\n    margin-top: 1rem;\n}\n\n.gradesBlock_content__selzf {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 100%;\n}\n\n.gradesBlock_labelContainer__FeuFF {\n    margin-bottom: 0;\n}\n\n.gradesBlock_label__l0zZs {\n    padding-left: .5em;\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n    color: #36899B;\n    margin: 0 0 .5rem 0;\n\n}\n\n.gradesBlock_checkboxes__MBuN9 {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 100%;\n    column-gap: 1rem;\n    margin-bottom: 1rem;\n}\n\n", "",{"version":3,"sources":["webpack://./src/pages/vacancySearchPage/components/gradesBlock/gradesBlock.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,2BAA2B;IAC3B,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,+BAA+B;IAC/B,eAAe;IACf,cAAc;IACd,mBAAmB;;AAEvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,2BAA2B;IAC3B,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".container {\n    margin-top: 1rem;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 100%;\n}\n\n.labelContainer {\n    margin-bottom: 0;\n}\n\n.label {\n    padding-left: .5em;\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n    color: #36899B;\n    margin: 0 0 .5rem 0;\n\n}\n\n.checkboxes {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    justify-content: flex-start;\n    width: 100%;\n    column-gap: 1rem;\n    margin-bottom: 1rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "gradesBlock_container__3J2Wz",
	"content": "gradesBlock_content__selzf",
	"labelContainer": "gradesBlock_labelContainer__FeuFF",
	"label": "gradesBlock_label__l0zZs",
	"checkboxes": "gradesBlock_checkboxes__MBuN9"
};
export default ___CSS_LOADER_EXPORT___;
