// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".articleBlock_container__p\\+Y4d {\n\tpadding-bottom: 1.5rem;\n\tpadding: 1rem;\n\tborder-radius: 1rem;\n\tborder: #36899B solid 0.1rem;\n\tbox-shadow: 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.2);\n\n\tmargin-bottom: 1rem;\n\tcursor: pointer;\n}\n\n.articleBlock_content__5Aers {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 0.5rem;\n}\n\n.articleBlock_articleNameSpan__2CWC4 {\n\tfont-family: Roboto, sans-serif;\n    font-size: 1.2rem;\n    color: #36899B;\n    margin-top: 0.5rem;\n    margin-bottom: 0.8rem;\n}\n\n.articleBlock_articleDescription__gnP-w {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 1rem;\n}\n\n.articleBlock_link__p-0gM {\n\ttext-decoration: none;\n  color: #35899B;\n}", "",{"version":3,"sources":["webpack://./src/pages/articlesPage/articlesListBlock/articleBlock/articleBlock.module.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,aAAa;CACb,mBAAmB;CACnB,4BAA4B;CAC5B,gDAAgD;;CAEhD,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,WAAW;AACZ;;AAEA;CACC,+BAA+B;IAC5B,iBAAiB;IACjB,cAAc;IACd,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,SAAS;AACV;;AAEA;CACC,qBAAqB;EACpB,cAAc;AAChB","sourcesContent":[".container {\n\tpadding-bottom: 1.5rem;\n\tpadding: 1rem;\n\tborder-radius: 1rem;\n\tborder: #36899B solid 0.1rem;\n\tbox-shadow: 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.2);\n\n\tmargin-bottom: 1rem;\n\tcursor: pointer;\n}\n\n.content {\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 0.5rem;\n}\n\n.articleNameSpan {\n\tfont-family: Roboto, sans-serif;\n    font-size: 1.2rem;\n    color: #36899B;\n    margin-top: 0.5rem;\n    margin-bottom: 0.8rem;\n}\n\n.articleDescription {\n\tdisplay: flex;\n\tflex-direction: row;\n\tgap: 1rem;\n}\n\n.link {\n\ttext-decoration: none;\n  color: #35899B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "articleBlock_container__p+Y4d",
	"content": "articleBlock_content__5Aers",
	"articleNameSpan": "articleBlock_articleNameSpan__2CWC4",
	"articleDescription": "articleBlock_articleDescription__gnP-w",
	"link": "articleBlock_link__p-0gM"
};
export default ___CSS_LOADER_EXPORT___;
