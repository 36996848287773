import { getApiCall } from "./apiCommon";
import { accountDataUrl, resumeSearchItemsUrl } from "../utils/apiUrls";
import accountImg from "../img/account-default-icon.svg";

export const getResumeItems = (grades, competenciesNames, pageNumber) => {

    const authToken = localStorage.getItem("authorizationToken");
    const config = { headers: { Authorization: `Token ${authToken}` } };
    return getApiCall(resumeSearchItemsUrl + "?" + paramsString(grades, competenciesNames, pageNumber), config)
        .then(value => {
            if (value.statusText === "OK") {
                return { statusText: "OK", data: processResumeItems(value) };
            } else {
                console.error("something wrong when call to resumes search endpoint. status code: " + value.status);
                return value;
            }
        });
}

const processResumeItems = apiOkValue => {
    return {
        totalPages: apiOkValue.data.total_pages, // TODO: correct 10 when onPageQuantity will be added
        currentPage: apiOkValue.data.page_num,
        resumeItems:
            apiOkValue.data.data.map((item, items) => {
                return {
                    id: item.id,
                    firstName: item.profile.first_name,
                    lastName: item.profile.last_name,
                    city: item.profile.city,
                    competenciesList: item.competencies,
                    currentDegree: item.education.map(eduItem => eduItem.universityDegree)[item.education.length - 1],
                    ageWithYears: "возраст не указан", // TODO add age and its handling latter
                    photoSrc: accountImg
                }
            })
    }
}

const paramsString = (grades, competenciesNames, pageNumber) => {
    let gradesString = "";
    if (!grades.find(grade => grade === "none")) {
        gradesString = grades.reduce((string, grade) => string + "grade=" + grade + "&", "");
        gradesString = gradesString.slice(0, gradesString.length - 1);
    }

    let competenciesString = competenciesNames.reduce((string, competence) => string + "cid=" + competence + "&", "");
    competenciesString = competenciesString.slice(0, competenciesString.length - 1);
    if (gradesString.length > 0) {
        competenciesString = "&" + competenciesString;
    }

    let paginatorString = "page=" + (pageNumber);
    if (competenciesString.length > 0 || gradesString.length > 0) paginatorString = "&" + paginatorString;

    return gradesString + competenciesString + paginatorString;

}

// TODO: return this when person age will be added
// const handleResumeItems = (items) => {
//     return items.map(item => {
//             item.age = getAgeWithText(item.age);
//             return item;
//         });
// }
//
// const getAgeWithText = (age) => {
//     const rest = age % 10;
//     if (rest === 1) {
//         return age + " год";
//     } else if (rest < 5) {
//         return age + " года";
//     } else {
//         return age + " лет";
//     }
// }