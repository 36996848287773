// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.companyBlock_pageHeader__xHjgj{\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1.375rem;\n\tcolor: #36899B;\n\tfont-weight: bold;\n}\n\n.companyBlock_square__q8J32{\n\tfont-weight: normal;\n\tfont-family: Roboto, sans-serif;\n\theight: auto;\n\tfont-size: 1.25rem;\n\tword-break: break-all;\n\tcolor: #36899B;\n\t\n}\n\n.companyBlock_container__l3bQR{\n\tdisplay: flex;\n\tflex-flow: row wrap;\n\tjustify-content: space-between;\n}\n\n.companyBlock_block__ok2TT{\n\tflex: 1 0;\n}\n\n.companyBlock_figure__sq739{\n\tmargin-right: 1rem;\n\twidth: 11rem;\n\theight: 11rem;\n\tborder-radius: 0.625rem;\t\n}", "",{"version":3,"sources":["webpack://./src/pages/vacancyViewPage/components/companyBlock/companyBlock.module.css"],"names":[],"mappings":";AACA;CACC,+BAA+B;CAC/B,mBAAmB;CACnB,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC,mBAAmB;CACnB,+BAA+B;CAC/B,YAAY;CACZ,kBAAkB;CAClB,qBAAqB;CACrB,cAAc;;AAEf;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,kBAAkB;CAClB,YAAY;CACZ,aAAa;CACb,uBAAuB;AACxB","sourcesContent":["\n.pageHeader{\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1.375rem;\n\tcolor: #36899B;\n\tfont-weight: bold;\n}\n\n.square{\n\tfont-weight: normal;\n\tfont-family: Roboto, sans-serif;\n\theight: auto;\n\tfont-size: 1.25rem;\n\tword-break: break-all;\n\tcolor: #36899B;\n\t\n}\n\n.container{\n\tdisplay: flex;\n\tflex-flow: row wrap;\n\tjustify-content: space-between;\n}\n\n.block{\n\tflex: 1 0;\n}\n\n.figure{\n\tmargin-right: 1rem;\n\twidth: 11rem;\n\theight: 11rem;\n\tborder-radius: 0.625rem;\t\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "companyBlock_pageHeader__xHjgj",
	"square": "companyBlock_square__q8J32",
	"container": "companyBlock_container__l3bQR",
	"block": "companyBlock_block__ok2TT",
	"figure": "companyBlock_figure__sq739"
};
export default ___CSS_LOADER_EXPORT___;
