import styles from "./textInfoBlock.module.css";

const Block = ({ info, header, align }) => {

    return (
        <div className={`styles.container ${alightStyles(align)}`}>
            <div className={styles.block}>
                <h1 className={styles.header}>{header}</h1>
                <h2 className={styles.textBox}>{info ? info : "Не указано"}</h2>
            </div>
        </div>
    )
};

const alightStyles = align => {
    switch (align) {
        case "left": return styles.alignLeft;
        case "right": return styles.alignRight;
        case "center": return styles.alignCenter;
    }
}

export default Block;
