// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.vacancyViewPage_container__0cx2O {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tfont-size: 1rem;\n}\n\n.vacancyViewPage_content__Rhag2 {\n\twidth: 60%;\n}", "",{"version":3,"sources":["webpack://./src/pages/vacancyViewPage/vacancyViewPage.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,UAAU;AACX","sourcesContent":["\n.container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tfont-size: 1rem;\n}\n\n.content {\n\twidth: 60%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vacancyViewPage_container__0cx2O",
	"content": "vacancyViewPage_content__Rhag2"
};
export default ___CSS_LOADER_EXPORT___;
