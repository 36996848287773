// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainContolsBlock_buttonBlock__ALARz {\n\n}\n\n.mainContolsBlock_CreateVacancyButton__XQf0B {\n\t    font-family: Roboto, sans-serif;\n    text-align: center;\n    font-size: 1.2rem;\n    font-weight: 400;\n    background-color: white;\n    border: none;\n    transition: 0.3s;\n    color: #A5D7E2;\n    text-decoration: none;\n}\n\n.mainContolsBlock_CreateVacancyButton__XQf0B:hover {\n    transition: 0.3s;\n    cursor: pointer;\n    color: #35899B;\n}", "",{"version":3,"sources":["webpack://./src/pages/vacancyListPage/mainControlsBlock/mainContolsBlock.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;KACK,+BAA+B;IAChC,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".buttonBlock {\n\n}\n\n.CreateVacancyButton {\n\t    font-family: Roboto, sans-serif;\n    text-align: center;\n    font-size: 1.2rem;\n    font-weight: 400;\n    background-color: white;\n    border: none;\n    transition: 0.3s;\n    color: #A5D7E2;\n    text-decoration: none;\n}\n\n.CreateVacancyButton:hover {\n    transition: 0.3s;\n    cursor: pointer;\n    color: #35899B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonBlock": "mainContolsBlock_buttonBlock__ALARz",
	"CreateVacancyButton": "mainContolsBlock_CreateVacancyButton__XQf0B"
};
export default ___CSS_LOADER_EXPORT___;
