import styles from "./previousJobs.module.css";
import {useState, useEffect} from "react";
import {emptyJobData} from "../../../../utils/constants";
import PreviousJobItem from "./previousJobItem/PreviousJobItem";
import {fetchJobsData, postJobData} from "../../../../api/ResumeApi";

const PreviousJobs = () => {

    const [jobItems, setJobItems] = useState([{...emptyJobData, id: 0}]);
    const [saveButtonStatus, setSaveButtonStatus] = useState(null);

    const getSaveButtonStyle = () => {
        if (saveButtonStatus == null) return "";
        else return saveButtonStatus ? styles.saveButtonSuccessCommit : styles.saveButtonUnsuccessCommit;
    }


    const handleJobAdd = () => {
        setJobItems([
            ...jobItems,
            {...emptyJobData, id: jobItems.reduce((currentValue, item) =>
                    Number(item.id) > currentValue ? Number(item.id) : currentValue, 0) + 1}
        ].sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1));
    }
    const handleJobDelete = deletedItem => {
        deletedItem.status === "created" ?
            setJobItems(
                jobItems
                    .filter(item => item.id !== deletedItem.id)
                    .sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1)
            )
            :
            setJobItems([
                ...jobItems
                    .filter(item => item.id !== deletedItem.id), { ...deletedItem, status: "deleted" }]
                .sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1)
            )
        ;
    }
    // Понять, как не ререндерить всё полотно при изменении одного символа в одном инпуте
    const handleJobItemChange = editedItem => {
        setJobItems(
            [...(jobItems.filter(item => item.id !== editedItem.id)), editedItem]
            .sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1)
        );
    }

    const replaceJobItems = (apiItems) => {
        const newItems = apiItems.filter(item => item.prevData.status !== "deleted").map(item => item.data);
        const refreshedItems = apiItems.map(item => item.prevData).filter(item => item.status !== "deleted");
        const deletedItems = apiItems.map(item => item.prevData).filter(item => item.status === "deleted");
        const correctedItems = jobItems
            .filter(oldItem => !deletedItems.find(deletedItem => oldItem.id === deletedItem.id)) // clean deleted
            .filter(oldItem => !refreshedItems.find(refreshedItem => oldItem.id === refreshedItem.id)) // clean refreshed
            .concat([...newItems]);
        setJobItems(correctedItems.sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1));
    }

    const handleJobsSave = () => {
        if (jobItems.find(item => item.status !== "existed")) {
            const results = jobItems
                .filter(jobItem => jobItem.status !== "existed")
                .map(jobItem => postJobData(jobItem));
            Promise.all(results)
                .then(values => {
                    replaceJobItems(values.filter(value => value.statusText === "OK"))
                    return values.filter(value => value.statusText !== "OK");
                }) // TODO: handle troubles in response
                .then(erroredValues => {
                    erroredValues.length === 0
                        ? setSaveButtonStatus(true)
                        : setSaveButtonStatus(false);
                    setTimeout(() => setSaveButtonStatus(null), 600);
                });
        }
    }

    useEffect(() => {
        fetchJobsData()
            .then(value => {
                if (value.statusText === "OK") {
                    setJobItems(value.data.sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1));
                } else {
                    console.error(`jobs data fetch error! Response code: ${value.status}`);
                }
            })
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.header}>
                    Стаж
                </h2>
                <p className={styles.tip}>
                    Уже был опыт работы? Круто, рассказывай!
                </p>
                {
                    jobItems.filter(item => item.status !== "deleted").map((item, index, items) => (
                            <div key={item.id} className={styles.jobBlockContainer}>
                                <PreviousJobItem
                                    initData={item}
                                    onChange={handleJobItemChange}
                                    onDelete={handleJobDelete}
                                />
                                {index !== items.length - 1 ? <div className={styles.divideLine}/> : null}
                            </div>
                        )
                    )
                }

                <div className={styles.addJobBlock}>
                    <button className={styles.addJobButton} onClick={handleJobAdd}>+ Добавить еще одно место работы</button>
                </div>
                <button className={`${styles.saveButton} ${getSaveButtonStyle()}`} onClick={handleJobsSave}>Сохранить</button>

            </div>
        </div>
    )
};

export default PreviousJobs;