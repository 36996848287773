// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vacancyIsActiveBlock_container__YEuOI {\n\tdisplay: flex;\n\tmargin-bottom: 1rem;\n\tjustify-content: center;\n}\n\n.vacancyIsActiveBlock_content__wGQVX {\n\tdisplay:flex;\n\tflex-direction: row;\n\talign-items: center;\n\twidth: 100%;\n}\n\n.vacancyIsActiveBlock_contorlsBlock__CY2X9 {\n\tdisplay: flex;\n}", "",{"version":3,"sources":["webpack://./src/pages/vacancyPublishPage/components/vacancyIsActiveBlock/vacancyIsActiveBlock.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;AACxB;;AAEA;CACC,YAAY;CACZ,mBAAmB;CACnB,mBAAmB;CACnB,WAAW;AACZ;;AAEA;CACC,aAAa;AACd","sourcesContent":[".container {\n\tdisplay: flex;\n\tmargin-bottom: 1rem;\n\tjustify-content: center;\n}\n\n.content {\n\tdisplay:flex;\n\tflex-direction: row;\n\talign-items: center;\n\twidth: 100%;\n}\n\n.contorlsBlock {\n\tdisplay: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vacancyIsActiveBlock_container__YEuOI",
	"content": "vacancyIsActiveBlock_content__wGQVX",
	"contorlsBlock": "vacancyIsActiveBlock_contorlsBlock__CY2X9"
};
export default ___CSS_LOADER_EXPORT___;
