import styles from "./tariffsApplicant.module.css"
import TariffCard from "../tariffCard/TariffCard";

const TariffsApplicantPage = () => {
    return (
        <div className={styles.container}>

            <div className={styles.headlineBlock}>
                <h1 className={styles.headline}>Тарифы для соскателя</h1>
            </div>

            <div className={styles.cardsBlock}>
                <TariffCard
                    headlineText={"Гражданин"}
                    active={true}
                    pricing={0}
                    description={"Размещение резюме в общем порядке"}
                    linkTo={`basic`}
                />
                <TariffCard
                    headlineText={"Знаменитость"}
                    active={false}
                    pricing={100}
                    description={"Размещение резюме в топе соискателей"}
                    linkTo={`pro`}
                />
                <TariffCard
                    headlineText={"Ключ от города"}
                    active={false}
                    pricing={200}
                    description={"Размещение резюме в топе соискателей + в разделах сайта"}
                    linkTo={`pro-max`}
                />
            </div>

        </div>
    )
};

export default TariffsApplicantPage;