import styles from "./changeCompanyPopup.module.css"
import InputField from "../../../../inputField/InputField";
import TextareaInput from "../../../../textarea/TextareaInput"
import { useEffect, useState } from "react";
import { getCompanyData, postCompanyData } from "../../../../../api/CompanyApi"
import { emptyCompanyData } from "../../../../../utils/constants"

const ChangeCompanyPopup = ({ onCancel }) => {

    const [companyData, setCompanyData] = useState(emptyCompanyData)
    const [isLoaded, setIsLoaded] = useState(false)

    const handleChange = fieldName => fieldValue => {
        setCompanyData({ ...companyData, [fieldName]: fieldValue })
    }
    const handleSave = e => {
        e.preventDefault();
        postCompanyData(companyData)
            .then(value => {
                if (value.statusText === "OK") {
                    setCompanyData(value.data)
                } else {
                    setCompanyData(emptyCompanyData);
                    console.error("Error when update company data! response code: " + value.status)
                }
            })
    }
    useEffect(() => {
        getCompanyData()
            .then(value => {
                if (value.statusText === "OK") {
                    setCompanyData(value.data);
                } else {
                    console.error("Error when fetch company data! response code: " + value.status)
                }
                setIsLoaded(true);
            })
    }, [])
    return (
        <div className={styles.container}>
            <div onClick={onCancel} className={styles.overlay} />
            <form className={styles.form} onSubmit={handleSave}>
                {
                    isLoaded
                        ? (
                            <div>
                                <h1 className={styles.header}>Информация о текущей компании</h1>
                                <InputField
                                    type={"text"} label={"Название компании"} placeholder={"Введите название компании"}
                                    value={companyData.name}
                                    required={true}
                                    invalidInputMessage={""}
                                    onChange={handleChange("name")}
                                />
                                <TextareaInput
                                    label={"Описание"}
                                    placeholder={"Введите описание компании"}
                                    value={companyData.description}
                                    required={true}
                                    invalidInputMessage={""}
                                    onChange={handleChange("description")}
                                />
                                <button
                                    className={styles.submitButton}
                                    type={"submit"}
                                >
                                    Сохранить
                                </button>
                            </div>
                        ) : (<p>Загрузка данных...</p>)
                }
            </form>

        </div>
    )
}

export default ChangeCompanyPopup;