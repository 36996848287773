import styles from "./vacancyListBlock.module.css";
import VacancyBlock from "./vacancyBlock/VacancyBlock";

const VacancyListBlock = ({vacancyList, onVacancyRemove}) => {
	return (
		<div className={styles.container}>

			<div className={styles.content}>

				<ul className={styles.vacancyList}>
					<li className={styles.resumeItemsBlock}>
						{vacancyList.map(item => (
							<VacancyBlock
								name={item.vacancyName}
								id={item.id}
								onRemove={onVacancyRemove}
							/>
						))}
					</li>

				</ul>

			</div>

		</div>
	)
};

export default VacancyListBlock;