import styles from "./tariffs.module.css"
import NavBar from "../../components/navBar/NavBar";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import TariffsApplicant from "./tariffsApplicant/TariffsApplicant";

const TariffsApplicantPage = () => {
    return (
        <div className={styles.container}>
            <NavBar />

            <TariffsApplicant className={styles.tariffs}/>

            <FooterImage />
        </div>
    )
};

export default TariffsApplicantPage;