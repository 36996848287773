import image from "../../../img/wg-nav-icon.svg"
import styles from "./navBarImage.module.css"

const NavBarImage = () => {
    return(
        <div>
            <img className={styles.image} src={image} alt={"cancel"} />
        </div>
    )
}

export default NavBarImage