export const LoginFormInitData = {
    email: "",
    password: "",

};

export const registrationFormInitData = {
    lastName: "",
    firstName: "",
    additionalName: "",
    phoneNumber: "+7",
    accountType: "Выберите тип аккаунта",
    email: "",
    password: "",
    passwordConfirm: "",

}

export const emptyProfileData = {
    lastName: "",
    firstName: "",
    additionalName: "",
    city: "",
    email: "",
    phoneNumber: "",
    group: "",
    companyName: "",

}

export const emptyJobPermissionsData = {
    citizenship: "",
    work_permit: "",

}

export const emptyEducationData = {
    universityName: "",
    universityDegree: "",
    graduationYear: "",
    educationFaculty: "",
    educationProfile: "",
    averageGrade: "",
    status: "created", // possible values: created, existed, deleted, edited
    id: 0,

}

export const emptyJobData = {
    jobBeginDate: null,
    jobEndDate: null,
    currentJob: false,
    companyName: "",
    jobPost: "",
    jobDescriptions: "",
    status: "created", // possible values: created, existed, deleted, edited
    id: 0,

}

export const emptyMotivationData = {
    aboutText: "",
    competences: [],

};

export const emptyCompanyData = {
    name: "",
    description: "",
    
}

export const emptySalaryData = {
    from: 0,
    to: 0,
    notStated: false,
    
}

export const emptyCompetencesData = [];

export const emptyResumeItemsData = [];

export const pageCounterWidth = 2;

export const emptyVacancyData = {
    title: "",
    isActive: true,
    description: "",
    region : "",
    salaryFrom : null,
    salaryTo : null,
    salaryNotStated : false,
    competencies : [],
    office : false,
    remote : false,
    lab : false,
    startup : false,
    organization: {name: ""}

}