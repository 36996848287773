import styles from "./profileFieldsCompanyField.module.css"
import ChangeCompanyPopup from "./changeCompanyPopup/ChangeCompanyPopup";
import {useState} from "react";

const ProfileFieldsCompanyField = ({companyName}) => {

    const [popupShow, setPopupShow] = useState(false);

    const togglePopup = () => {
        console.log(popupShow);
        setPopupShow(!popupShow);
    }

    return (
        <div className={styles.container}>
            <span className={styles.label}>Компания</span>
            <div className={styles.content}>
                <span className={styles.companyStatus}>{companyName === "" ? "Не указана" : companyName}</span>
                <button className={styles.companySettings} onClick={togglePopup}>Подробнее</button>
            </div>
            {popupShow && <ChangeCompanyPopup onCancel={togglePopup}/>}
        </div>
    )
}

export default ProfileFieldsCompanyField;