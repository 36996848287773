import styles from "./previousJobs.module.css";

const PreviousJobs = ({ company,post, start,responsibilities, end, showHeader }) => {  
    return (
        <div className={styles.container}>
            {showHeader && <h2 className={styles.mainHeader}>Стаж</h2>}
            
            {!showHeader && <div className={styles.divideLine}></div>}

            <div className={styles.table}>
                <div className={styles.column}>
                    <div className={styles.header}>Название компании</div>
                    {!company ? <div className={styles.noData}>нет данных</div> : <div className={styles.content}>{company}</div>}


                    <div className={styles.header}>Должность</div>
                    {!post ? <div className={styles.noData}>нет данных</div> : <div className={styles.content}>{post}</div>}


                </div>
                <div className={styles.column}>
                    <div className={styles.header}>Начало работы</div>
                    {!start ? <div className={styles.noData}>нет данных</div> : <div className={styles.content}>{start}</div>}

                    <div className={styles.header}>Обязанности</div>
                    {!responsibilities ? <div className={styles.noData}>нет данных</div> : <div className={styles.content}>{responsibilities}</div>}
                </div>
                <div className={styles.column}>
                    <div className={styles.header}>Окончание работы</div>
                    {!end ? <div className={styles.noData}>нет данных</div> : <div className={styles.content}>{end}</div>}
                </div>
            </div>
        </div>
    )
};

export default PreviousJobs;