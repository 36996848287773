// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profileFieldsCompanyField_container__so-Lj {\n\tmargin-top: 0.6em;\n}\n\n.profileFieldsCompanyField_label__WkOFq {\n\tpadding-left: .5em;\n\tcolor: #A5D7E2;\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1rem;\n}\n\n.profileFieldsCompanyField_content__pKB0D {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.profileFieldsCompanyField_companyStatus__JgXkH {\n\tpadding-left: .5em;\n\tcolor: #36899B;\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1rem;\n}\n\n.profileFieldsCompanyField_companySettings__wRhJ1 {\n\tbackground-color: white;\n\tborder: none;\n\n\tfont-size: 1rem;\n\tfont-weight: 600;\n\tfont-family: Roboto, sans-serif;\n\tcolor: #36899B;\n\ttext-decoration: underline;\n\n\tcursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/profileContent/profileFieldsBlock/profileFieldsCompanyField/profileFieldsCompanyField.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;CAClB,cAAc;CACd,+BAA+B;CAC/B,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,8BAA8B;AAC/B;;AAEA;CACC,kBAAkB;CAClB,cAAc;CACd,+BAA+B;CAC/B,eAAe;AAChB;;AAEA;CACC,uBAAuB;CACvB,YAAY;;CAEZ,eAAe;CACf,gBAAgB;CAChB,+BAA+B;CAC/B,cAAc;CACd,0BAA0B;;CAE1B,eAAe;AAChB","sourcesContent":[".container {\n\tmargin-top: 0.6em;\n}\n\n.label {\n\tpadding-left: .5em;\n\tcolor: #A5D7E2;\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1rem;\n}\n\n.content {\n\tdisplay: flex;\n\tjustify-content: space-between;\n}\n\n.companyStatus {\n\tpadding-left: .5em;\n\tcolor: #36899B;\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1rem;\n}\n\n.companySettings {\n\tbackground-color: white;\n\tborder: none;\n\n\tfont-size: 1rem;\n\tfont-weight: 600;\n\tfont-family: Roboto, sans-serif;\n\tcolor: #36899B;\n\ttext-decoration: underline;\n\n\tcursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "profileFieldsCompanyField_container__so-Lj",
	"label": "profileFieldsCompanyField_label__WkOFq",
	"content": "profileFieldsCompanyField_content__pKB0D",
	"companyStatus": "profileFieldsCompanyField_companyStatus__JgXkH",
	"companySettings": "profileFieldsCompanyField_companySettings__wRhJ1"
};
export default ___CSS_LOADER_EXPORT___;
