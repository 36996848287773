import styles from "./resumePictureBlock.module.css"
import defaultVacancyPicture from "../../../../img/account-default-icon.svg"

const ResumePictureBlock = () => {
    return (
        <div className={styles.container}>
            <img src={defaultVacancyPicture} className={styles.vacancyPhoto} alt={"vacancyPic"} />
        </div>
    )
};
export default ResumePictureBlock;