import styles from "./registrationFormSelect.module.css"
import {useState} from "react";

const Select = ({value, options, onChange, pattern}) => {
    const [isOpen, setIsOpen] = useState(false);

    const setOption = (e) => {
        e.stopPropagation();
        setIsOpen(false);
        onChange(e.target.value);
    }

    return (
        <div className={styles.block}>
            <div
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
                onBlur={(e) => {
                    const realTarget = e.relatedTarget;
                    if (realTarget == null || realTarget.parentElement.nodeName !== "UL") setIsOpen(false)
                }}
                tabIndex={0}
                className={styles.container}
            >
                <input
                    type={"text"}
                    className={styles.value}
                    value={value}
                    pattern={pattern}
                />
                <div className={styles.dividerLine}/>
                <div className={styles.caret}/>

                <ul className={`${styles.options} ${isOpen ? styles.show : ""}`}>
                    {options.map(option => (
                        <input key={option.value}
                               value={option.value}
                               className={`${styles.option} ${value === option.value ? styles.selected : ""}`}
                               onClick={setOption}
                               readOnly={true}
                        />
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Select;