import styles from "./vacancyBlock.module.css";
import { changeCurentPage } from "./../../../../api/routeCommon";

const VacancyBlock = ({ name, id, onRemove }) => {
	
	const openEditPage = () => {
		changeCurentPage("/vacancy/" + id);
	};
	
	const removeVacancy = () => {
		onRemove(id)
	}

	return (
		<div className={styles.container}>

			<div className={styles.content}>

				<span className={styles.vacancyNameSpan}>{name}</span>

				<div className={styles.vacancyControls}>
					<div className={styles.editVacancyButtonBlock}>
						<button className={styles.editVacancyButton} onClick={openEditPage}>
							Редактировать вакансию
						</button>
					</div>
					<div className={styles.removeVacancyButtonBlock}>
						<button className={styles.removeVacancyButton} onClick={removeVacancy}>Удалить вакансию</button>
					</div>
				</div>

			</div>

		</div>
	)
};

export default VacancyBlock;