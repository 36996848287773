import styles from "./inputField.module.css"
import {useState} from "react";



const InputField = ({type, label, placeholder, value, min, max, step, onChange, invalidInputMessage, pattern, required, disabled}) => {
    if (type === "tel") value = formatPhoneNumber(value);


    const [focused, setFocused] = useState(false);
    const handleInput = e => {
        type === "tel" ?
            setFocused(e.target.value !== "+7()" && e.target.value !== "+7") :
            setFocused(e.target.value !== "");
    }

    const handleChange = newValue => {
        if (type === "date") {
            newValue === "" ? onChange(null) : onChange(newValue);
            return;
        }
        if (type === "tel") {
            newValue = formatPhoneNumber(newValue);
        }
        onChange(newValue);
    }

    return(
            <div className={styles.container}>
                <label className={styles.label}>{label}</label>
                <br/>
                <input className={styles.input}
                       type={type}
                       placeholder={placeholder}
                       value={value}
                       min={min}
                       max={max}
                       step={step}
                       pattern={pattern}
                       required={required}
                       focused={focused.toString()}
                       onInput={handleInput}
                       onChange={e => handleChange(e.target.value)}
                       disabled={disabled}
                />
                <br/>
                <div className={styles.invalidInputSpanBlock}>
                    <span className={styles.invalidInputSpan}>{invalidInputMessage}</span>
                </div>
            </div>
    );
};
// +7(981)461-03-66
const formatPhoneNumber = (value) => {
    const onlyDigits = value.replace(/[^[\d]/g, "");
    const len = onlyDigits.length;
    if (len < 2) return "+7";
    if (len < 5) return `+7(${onlyDigits.slice(1, len)})`;
    if (len < 8) return `+7(${onlyDigits.slice(1, 4)})-${onlyDigits.slice(4, len)}`;
    if (len < 10) return `+7(${onlyDigits.slice(1, 4)})-${onlyDigits.slice(4, 7)}-${onlyDigits.slice(7, len)}`;
    if (len < 12) return `+7(${onlyDigits.slice(1, 4)})-${onlyDigits.slice(4, 7)}-${onlyDigits.slice(7, 9)}-${onlyDigits.slice(9, len)}`;
    else return `+7(${onlyDigits.slice(1, 4)})-${onlyDigits.slice(4, 7)}-${onlyDigits.slice(7, 9)}-${onlyDigits.slice(9, 11)}`;
}

export default InputField;