// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.vacancyAdditionalInfo_container__LERC0 {\n    padding-bottom: 1.5rem;\n}\n\n.vacancyAdditionalInfo_content__QPmJ7 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.vacancyAdditionalInfo_header__MU0y4 {\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.4rem;\n    color: #36899B;\n\n    margin-top: 1.2rem;\n    margin-bottom: .5rem;\n}\n.vacancyAdditionalInfo_inputsBlock__yz9c7 {\n    display: flex;\n    flex-direction: row;\n    justify-content: start;\n    gap: 1rem;\n    width: 100%;\n    margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/vacancyPublishPage/components/vacancyAdditionalInfo/vacancyAdditionalInfo.module.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,+BAA+B;IAC/B,iBAAiB;IACjB,cAAc;;IAEd,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,SAAS;IACT,WAAW;IACX,mBAAmB;AACvB","sourcesContent":["\n.container {\n    padding-bottom: 1.5rem;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.header {\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.4rem;\n    color: #36899B;\n\n    margin-top: 1.2rem;\n    margin-bottom: .5rem;\n}\n.inputsBlock {\n    display: flex;\n    flex-direction: row;\n    justify-content: start;\n    gap: 1rem;\n    width: 100%;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vacancyAdditionalInfo_container__LERC0",
	"content": "vacancyAdditionalInfo_content__QPmJ7",
	"header": "vacancyAdditionalInfo_header__MU0y4",
	"inputsBlock": "vacancyAdditionalInfo_inputsBlock__yz9c7"
};
export default ___CSS_LOADER_EXPORT___;
