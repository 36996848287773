// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.header_container__LxweY {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: start;\n\talign-items: center;\n\n\tmargin-bottom: 2rem;\n}\n\n.header_pageHeader__6RniC{\n\talign-items: center;\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1.8rem;\n\tcolor: #36899B;\n\n\tmargin-top: 2.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/articlesPage/header/header.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,sBAAsB;CACtB,sBAAsB;CACtB,mBAAmB;;CAEnB,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;CACnB,+BAA+B;CAC/B,iBAAiB;CACjB,cAAc;;CAEd,kBAAkB;AACnB","sourcesContent":["\n.container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: start;\n\talign-items: center;\n\n\tmargin-bottom: 2rem;\n}\n\n.pageHeader{\n\talign-items: center;\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1.8rem;\n\tcolor: #36899B;\n\n\tmargin-top: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "header_container__LxweY",
	"pageHeader": "header_pageHeader__6RniC"
};
export default ___CSS_LOADER_EXPORT___;
