import styles from "./description.module.css"

const Description = ({ description }) => {
    return (
        <div className={styles.container}>
            <h2 className={styles.header}>О себе</h2>
            <div className={styles.text}>
                {description !== "" ? description : "Раздел пока не заполнен"}
            </div>
        </div>
    )
};
export default Description;