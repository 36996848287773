// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".educationItem_educationBlock__jUYxJ {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n}\n\n.educationItem_flexGrow3__kVYQ2 {\n    flex-grow: 100;\n}\n\n.educationItem_flexGrow1__KcgWq {\n    flex-basis: 11rem;\n}\n\n.educationItem_inputsBlock__Jwlo9 {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 90%;\n    margin-bottom: 1rem;\n}\n\n.educationItem_deleteButtonBlock__Hle\\+Y {\n    width: 90%;\n    display: flex;\n    flex-flow: row;\n    justify-content: end;\n\n    margin-bottom: 1rem;\n    margin-top: -.5rem;\n}\n\n.educationItem_saveButton__1PgE2:hover {\n    background: #cfe7f3;\n}", "",{"version":3,"sources":["webpack://./src/pages/resumePage/components/educations/educationItem/educationItem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,cAAc;IACd,oBAAoB;;IAEpB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".educationBlock {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n}\n\n.flexGrow3 {\n    flex-grow: 100;\n}\n\n.flexGrow1 {\n    flex-basis: 11rem;\n}\n\n.inputsBlock {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 90%;\n    margin-bottom: 1rem;\n}\n\n.deleteButtonBlock {\n    width: 90%;\n    display: flex;\n    flex-flow: row;\n    justify-content: end;\n\n    margin-bottom: 1rem;\n    margin-top: -.5rem;\n}\n\n.saveButton:hover {\n    background: #cfe7f3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"educationBlock": "educationItem_educationBlock__jUYxJ",
	"flexGrow3": "educationItem_flexGrow3__kVYQ2",
	"flexGrow1": "educationItem_flexGrow1__KcgWq",
	"inputsBlock": "educationItem_inputsBlock__Jwlo9",
	"deleteButtonBlock": "educationItem_deleteButtonBlock__Hle+Y",
	"saveButton": "educationItem_saveButton__1PgE2"
};
export default ___CSS_LOADER_EXPORT___;
