import styles from "./tariffs.module.css"
import NavBar from "../../components/navBar/NavBar";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import TariffsOrganization from "./tariffsOrganization/TariffsOrganization";

const TariffsOrganizationPage = () => {
    return (
        <div className={styles.container}>
            <NavBar />

            <TariffsOrganization />

            <FooterImage />
        </div>
    )
};

export default  TariffsOrganizationPage;