import styles from "./textareaInput.module.css"
import {useEffect, useRef, useState} from "react";

const TextareaInput = ({ label, placeholder, value, onChange, required}) => {

    const [textareaHeight, setTextareaHeight] = useState(96); // 96px initial height
    const textareaRef = useRef(null);
    useEffect(() => {
        refreshHeight(textareaRef.current);
    }, [])
    const handleChange = element => {
        refreshHeight(element);
        onChange(element.value);
    }
    const refreshHeight = element => {
        const prevHeight = element.style.height;
        element.style.height = 0;
        const lineHeight = parseFloat(getComputedStyle(element).lineHeight);
        const lines = Math.ceil( element.scrollHeight / lineHeight);
        element.style.height = prevHeight;
        setTextareaHeight(Math.ceil(lines * lineHeight))
    }

    return(
        <div className={styles.container}>
            <label className={styles.label}>{label}</label>
            <br/>
            <textarea
                ref={textareaRef}
                className={styles.input}
                placeholder={placeholder}
                value={value}
                required={required}
                onChange={e => handleChange(e.target)}
                style={{height: textareaHeight}}
            />
            <br/>
        </div>
    );
};

export default TextareaInput;