import styles from "./competencesCounters.module.css"
import PracticePicture from "../../../img/PracticePicture.svg"
import TheoryPicture from "../../../img/TheoryPicture.svg"
import ChangedTheoryPicture from "../../../img/ChangedTheoryPicture.svg"

const CompetencesCounters = ({ theoryCount, practiceCount }) => {

    return (
        <div className={styles.container}>
            <div className={styles.counterBlock}>
                <img className={styles.theoryImg} src={ChangedTheoryPicture} title="Теория" />
                <div className={styles.theoryCnt}>{theoryCount}</div>
            </div>
            <div className={styles.counterBlock}>
                <img className={styles.practiceImg} src={PracticePicture} title="Практика" />
                <div className={styles.practiceCnt}>{practiceCount}</div>
            </div>
        </div>
    )
}

export default CompetencesCounters;
