import styles from "./educations.module.css";
import {useState, useEffect} from "react";
import EducationItem from "./educationItem/EducationItem";
import {emptyEducationData} from "../../../../utils/constants"
import {fetchEducationsData, postEducationData} from "../../../../api/ResumeApi";

const Educations = () => {

    const [educationItems, setEducationItems] = useState([emptyEducationData]);
    const [saveButtonStatus, setSaveButtonStatus] = useState(null);

    const getSaveButtonStyle = () => {
        if (saveButtonStatus == null) return "";
        else return saveButtonStatus ? styles.saveButtonSuccessCommit : styles.saveButtonUnsuccessCommit;
    }

    const handleEducationAdd = () => {
        setEducationItems([
            ...educationItems,
            {...emptyEducationData, id: educationItems.reduce((currentValue, item) =>
                    Number(item.id) > currentValue ? Number(item.id) : currentValue, 0) + 1}
        ].sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1));
    }
    const handleEducationDelete = deletedItem => {
        deletedItem.status === "created" ?
            setEducationItems(
                educationItems
                    .filter(item => item.id !== deletedItem.id)
                    .sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1)
            )
        :
            setEducationItems([
                ...educationItems
                    .filter(item => item.id !== deletedItem.id), { ...deletedItem, status: "deleted" }]
                    .sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1)
            )
        ;
    }
    // Понять, как не ререндерить всё полотно при изменении одного символа в одном инпуте
    const handleEducationItemChange = editedItem => {
        setEducationItems(
            [...(educationItems.filter(item => item.id !== editedItem.id)), editedItem]
                .sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1)
        );
    }

    const replaceEducationItems = (apiItems) => {
        const newItems = apiItems.filter(item => item.prevData.status !== "deleted").map(item => item.data);
        const refreshedItems = apiItems.map(item => item.prevData).filter(item => item.status !== "deleted");
        const deletedItems = apiItems.map(item => item.prevData).filter(item => item.status === "deleted");
        const correctedItems = educationItems
            .filter(oldItem => !deletedItems.find(deletedItem => oldItem.id === deletedItem.id)) // clean deleted
            .filter(oldItem => !refreshedItems.find(refreshedItem => oldItem.id === refreshedItem.id)) // clean refreshed
            .concat([...newItems]);
        setEducationItems(correctedItems.sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1));
    }

    const handleEducationsSave = () => {
        if (educationItems.find(item => item.status !== "existed")) {
            const results = educationItems
                .filter(educationItem => educationItem.status !== "existed")
                .map(educationItem => postEducationData(educationItem));
            Promise.all(results)
                .then(values => {
                    replaceEducationItems(values.filter(value => value.statusText === "OK"));
                    return values.filter(value => value.statusText !== "OK");
                }) // TODO: handle troubles in response
                .then(erroredValues => {
                    erroredValues.length === 0
                        ? setSaveButtonStatus(true)
                        : setSaveButtonStatus(false);
                    setTimeout(() => setSaveButtonStatus(null), 600);
                });
        }
    }

    useEffect(() => {
        fetchEducationsData()
            .then(value => {
                if (value.statusText === "OK") {
                    setEducationItems(value.data.sort((a, b) => a.id === b.id ? 0 : a.id > b.id ? 1 : -1));
                } else {
                    console.error(`educations data fetch error! Response code: ${value.status}`);
                }
            })
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.header}>
                    Образование
                </h2>
                <p className={styles.tip}>
                    Учение - свет. Расскажи, где просветлялся
                </p>

                {
                    educationItems.filter(item => item.status !== "deleted").map((item, index, items) => (
                        <div key={item.id} className={styles.educationBlockContainer}>
                            <EducationItem
                                initData={item}
                                onChange={handleEducationItemChange}
                                onDelete={handleEducationDelete}
                                onSave={handleEducationsSave}
                            />
                            {index !== items.length - 1 ? <div className={styles.divideLine}/> : null}
                        </div>
                        )
                    )
                }

                <div className={styles.addEducationBlock}>
                    <button className={styles.addEducationButton} onClick={handleEducationAdd}>+ Добавить еще одно образование</button>
                </div>
                <button className={`${styles.saveButton} ${getSaveButtonStyle()}`} type="submit" form="Education">Сохранить</button>
            </div>
        </div>
    )
};

export default Educations;