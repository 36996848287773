import styles from "./articlesPage.module.css";
import NavBar from "../../components/navBar/NavBar";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import Header from "./header/Header";
import ArticlesListBlock from "./articlesListBlock/ArticlesListBlock";
import { getArticles } from "../../api/ArticlesApi";
import { useEffect, useState } from "react";

const ArticlesPage = () => {

	const [articlesListData, setArticlesListData] = useState([])

	useEffect(() => {
		getArticles()
			.then(data => {
				if (data !== null) {
					setArticlesListData(data);
				}
			})
	}, []);

	return (
		<div className={styles.container}>

			<NavBar />

			<div className={styles.content}>

				<Header />

				<ArticlesListBlock articlesList={articlesListData} />

			</div>

			<FooterImage />

		</div>
	)
};

export default ArticlesPage;