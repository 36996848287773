import styles from "./articlesListBlock.module.css";
import ArticleBlock from "./articleBlock/ArticleBlock";

const ArticlesListBlock = ({articlesList}) => {
	return (
		<div className={styles.container}>

			<div className={styles.content}>

				<ul className={styles.vacancyList}>
					<li className={styles.resumeItemsBlock}>
						{articlesList.map(item => (
							<ArticleBlock
								title={item.title}
                subtitle={item.subtitle}
								id={item.id}
							/>
						))}
					</li>

				</ul>

			</div>

		</div>
	)
};

export default ArticlesListBlock;