import styles from "./vacancyViewPage.module.css";
import NavBar from "../../components/navBar/NavBar";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import HeaderBlock from "./components/headerBlock/HeaderBlock";
import CompanyBlock from "./components/companyBlock/CompanyBlock";
import TextInfoBlock from "../../components/textInfoBlock/TextInfoBlock";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getVacacyById } from "../../api/VacancyApi";
import { emptyVacancyData } from "../../utils/constants";

const VacancyViewPage = () => {

	const params = useParams();
	const [pageFields, setPageFields] = useState(emptyVacancyData);

	useEffect(() => {
		getVacacyById(params.id)
			.then(data => {
				if (data !== null) {
					setPageFields(data)
				}
			});
	}, []);
	return (
		<div className={styles.container}>

			<NavBar />

			{pageFields.organization !== undefined ?
			<div className={styles.content}>

				<HeaderBlock
					header="Вакансия"
					info={pageFields.title}
				/>

				<CompanyBlock
					header="О компании"
					info={pageFields.organization.name}
				/>

				<TextInfoBlock
					header="Описание компании"
					info={pageFields.organization.description}
					align="left"
				/>

				<TextInfoBlock
					header="Компетенции"
					info={pageFields.competencies.map(c => c.name).join(", ")}
					align="left"
				/>

				<TextInfoBlock
					header="Регион"
					info="Москва"
					align="left"
				/>
				<TextInfoBlock
					header="Заработная плата"
					info={salaryinfo(pageFields)}
					align="left"
				/>

				<TextInfoBlock
					header="Удалёнка"
					info={pageFields.remote ? "Возможна удаленная работа" : "Работа только в офисе"}
					align="left"
				/>

			</div> : <div> Загрузка... </div>}

			<FooterImage />

		</div>
	)
};

const salaryinfo = (pageFields) => {
	return pageFields.salaryNotStated
		? "Не указана"
		: pageFields.salaryFrom
			? `От ${pageFields.salaryFrom}` + (pageFields.salaryTo ? ` до ${pageFields.salaryTo}` : "")
			: pageFields.salaryTo
				? `До ${pageFields.salaryTo}`
				: "Не указана";
}

export default VacancyViewPage;