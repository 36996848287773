import styles from "./previousJobItem.module.css"
import InputField from "../../../../../components/inputField/InputField";
import Checkbox from "../../../../../components/checkbox/Checkbox";
import TextareaInput from "../../../../../components/textarea/TextareaInput";
import {useState} from "react"
import DeleteButton from "../../../../../components/deleteButton/DeleteButton";

const PreviousJobItem = ( {initData, onChange, onDelete} ) => {

    const [jobData, setJobData] = useState(initData);

    const handleChange = fieldName => fieldValue => {
        const newJobData = {...jobData, [fieldName]: fieldValue};
        if (newJobData.status === "existed") newJobData.status = "edited";
        setJobData(newJobData);
        onChange(newJobData);
    }

    const handleDelete = () => {
        onDelete(jobData);
    }

    return (
        <div className={styles.jobBlock}>
            <div className={styles.inputsBlock}>
                <InputField
                    type={"date"} label={"Дата начала"} placeholder={""}
                    value={jobData.jobBeginDate === null ? "" : jobData.jobBeginDate}
                    onChange={handleChange("jobBeginDate")}
                    invalidInputMessage={""}
                />
                <InputField
                    type={"date"} label={"Дата окончания"} placeholder={""}
                    value={jobData.jobEndDate === null ? "" : jobData.jobEndDate}
                    onChange={handleChange("jobEndDate")}
                    disabled={jobData.currentJob}
                    invalidInputMessage={""}
                />
                <Checkbox
                    label={"Текущая работа"}
                    value={jobData.currentJob}
                    id={jobData.id}
                    onChange={handleChange("currentJob")}
                />
            </div>
            <div className={styles.inputsBlock}>
                <InputField
                    type={"text"} label={"Компания"} placeholder={"Введите компанию"}
                    value={jobData.companyName}
                    onChange={handleChange("companyName")}
                    invalidInputMessage={""}
                />
                <InputField
                    type={"text"} label={"Должность"} placeholder={"Введите должность"}
                    value={jobData.jobPost}
                    onChange={handleChange("jobPost")}
                    invalidInputMessage={""}
                />
            </div>
            <div className={styles.inputsBlock}>
                <TextareaInput
                    label={"Основные обязанности и зоны ответственности"}
                    value={jobData.jobDescriptions}
                    onChange={handleChange("jobDescriptions")}
                    placeholder={"Расскажите подробнее о вашей работе"}
                />
            </div>
            <div className={styles.deleteButtonBlock}>
                <DeleteButton
                    buttonText={"Удалить запись о месте работы"}
                    onDelete={handleDelete}
                />
            </div>
        </div>
    )
};

export default PreviousJobItem;