import styles from "./autoscalingTextInput.module.css"
import {useEffect, useState} from "react";

const AutoscalingTextInput = ( {onValueSubmit, placeholder, autofillList} ) => {

    const [autofillItems, setAutofillItems] = useState(autofillList);
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState("");
    useEffect(() => {
        if (autofillList) setAutofillItems(autofillList)
    }, [autofillList])

    const handleChange = e => {
        if (e.key === "Enter") {
            if (e.target.value.length > 0) {
                onValueSubmit(e.target.value);
            }
            setValue("");
            e.target.blur();
        }
    };
    const handleFocus = () => {
        setFocused(true);
    }
    const handleBlur = e => {
        if (e.relatedTarget && e.relatedTarget.className === styles.autofillListItem) {
            onValueSubmit(e.relatedTarget.innerText);
            setValue("");
        } else if (e.target.value.length > 0) {
            onValueSubmit(e.target.value);
            setValue("");
        }
        setFocused(false);
    }

    // TODO: move this logic to api logic
    const autofillPrepare = autofillList ? autofillItems.filter(item => item.toLowerCase().startsWith(value.toLowerCase())).sort((a, b) => {
        return a.localeCompare(b, undefined, {sensitivity: 'base'});
    }) : null;

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <span className={styles.inputWrap}>
                    <span className={styles.widthMachine} aria-hidden="true">{value}</span>
                    <input
                        className={styles.input}
                        value={value}
                        type="text"
                        onFocus={handleFocus}
                        placeholder={placeholder}
                        onChange={e => setValue(e.target.value)}
                        onKeyUp={e => handleChange(e)}
                        onBlur={e => handleBlur(e)}
                    />
                </span>
                { autofillList && autofillPrepare.length && focused ? (
                    <div className={styles.autofillListBlock}>
                        <ul className={styles.autofillList}>
                            {autofillPrepare.map(autofillListItem => (
                                <li className={styles.autofillListItemBlock}>
                                    <button className={styles.autofillListItem}>
                                        {autofillListItem}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                ) : null
                }
            </div>
        </div>
    );
};

export default AutoscalingTextInput;

