// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.articlePage_container__RQ-\\+- {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tfont-size: 1rem;\n}\n\n.articlePage_content__FW3IL {\n\twidth: 85%;\n}\n\n.articlePage_textPart__SP8kX {\n\tword-wrap: break-word;\n}\n\n.articlePage_image__\\+FOtN {\n\twidth: 80%;\n\talign-self: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/articlePage/articlePage.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,UAAU;CACV,kBAAkB;AACnB","sourcesContent":["\n.container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tfont-size: 1rem;\n}\n\n.content {\n\twidth: 85%;\n}\n\n.textPart {\n\tword-wrap: break-word;\n}\n\n.image {\n\twidth: 80%;\n\talign-self: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "articlePage_container__RQ-+-",
	"content": "articlePage_content__FW3IL",
	"textPart": "articlePage_textPart__SP8kX",
	"image": "articlePage_image__+FOtN"
};
export default ___CSS_LOADER_EXPORT___;
