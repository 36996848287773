// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.vacancyRegion_container__TipQQ {\n}\n\n.vacancyRegion_content__23ET\\+ {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.vacancyRegion_inputsBlock__W8Hqu {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 100%;\n    margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/vacancyPublishPage/components/vacancyRegion/vacancyRegion.module.css"],"names":[],"mappings":";AACA;AACA;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,WAAW;IACX,mBAAmB;AACvB","sourcesContent":["\n.container {\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.inputsBlock {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 100%;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vacancyRegion_container__TipQQ",
	"content": "vacancyRegion_content__23ET+",
	"inputsBlock": "vacancyRegion_inputsBlock__W8Hqu"
};
export default ___CSS_LOADER_EXPORT___;
