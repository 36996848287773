// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footerImage_FooterImageBlock__N8KUl {\n    z-index: -1;\n    position:fixed;\n    bottom:0;\n}\n\n.footerImage_FooterImage__E1kCp {\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/iconsAndImages/footerImage/footerImage.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,QAAQ;AACZ;;AAEA;IACI,WAAW;AACf","sourcesContent":[".FooterImageBlock {\n    z-index: -1;\n    position:fixed;\n    bottom:0;\n}\n\n.FooterImage {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FooterImageBlock": "footerImage_FooterImageBlock__N8KUl",
	"FooterImage": "footerImage_FooterImage__E1kCp"
};
export default ___CSS_LOADER_EXPORT___;
