import styles from "./profileFieldsInputField.module.css"
import crossIcon from "../../../../img/input-cross-icon.svg";
import {useState} from "react";

const ProfileFieldsInputField = ({type, placeholder, label, onChange, onInitDataChange, pattern, required, initialValue, value, invalidInputMessage, infoMessage, onProfileInfoSent}) => {

    if (type === "tel") value = formatPhoneNumber(value);

    const [valueCommitted, setValueCommitted] = useState(null);
    const [focused, setFocused] = useState(false);
    const handleInput = e => {
        type === "tel" ?
            setFocused(e.target.value !== "+7()" && e.target.value !== "+7") :
            setFocused(e.target.value !== "");
    }
    const sendInfoToServer = e => {
        if (value !== initialValue) {
            console.log("sending data...");
            onProfileInfoSent(value).then( status => {
                if (status === "OK") {
                    onInitDataChange(value);
                    setValueCommitted(true);
                    setTimeout(() => setValueCommitted(null), 600);
                } else {
                    setValueCommitted(false);
                    setTimeout(() => setValueCommitted(null), 600);
                }
            });
        }
    }

    function getCommittedInputStyle(valueCommitted) {
        if (valueCommitted == null) return "";
        else return valueCommitted ? styles.inputFieldSuccessCommit : styles.inputFieldUnsuccessCommit;
    }

    return(
        <div className={styles.container}>
            <label className={styles.label}>{label}</label>
            <br/>
            <div className={styles.inputContainer}>
                <input className={`${getCommittedInputStyle(valueCommitted)} ${styles.inputField}`}
                       type={type}
                       placeholder={placeholder}
                       value={value}
                       focused={focused.toString()}
                       pattern={pattern}
                       required={required}
                       onInput={handleInput}
                       onChange={e => onChange(e.target.value)}
                       onBlur={e => sendInfoToServer(e)}
                />
                <div className={styles.inputCancelInfoMessageBlock}>
                    <a
                        className={`${styles.crossIconBlock} ${initialValue !== value ? styles.crossIconBlockShow : ""}`}
                        onClick={e => {onChange(initialValue)}}
                        onMouseDown={e => e.preventDefault()}
                    >
                        <img src={crossIcon} alt={"cancel"} />
                    </a>
                    <div className={styles.inputCancelInfoMessage}>
                        <span className={styles.inputCancelInfoMessageSpan}>Отменить изменение</span>
                    </div>
                </div>
            </div>
            <br/>
            <span className={styles.invalidInputSpan}>{invalidInputMessage}</span>
            <span className={styles.infoSpan}>{infoMessage}</span>
        </div>
    );
};

// +7(981)-461-03-66
const formatPhoneNumber = (value) => {
    const onlyDigits = value.replace(/[^[\d]/g, "");
    const onlyDigitsLength = onlyDigits.length;
    if (onlyDigitsLength < 2) return "+7";
    if (onlyDigitsLength < 5) return `+7(${onlyDigits.slice(1, onlyDigitsLength)}`;
    if (onlyDigitsLength < 8) return `+7(${onlyDigits.slice(1, 4)})-${onlyDigits.slice(4, onlyDigitsLength)}`;
    if (onlyDigitsLength < 10) return `+7(${onlyDigits.slice(1, 4)})-${onlyDigits.slice(4, 7)}-${onlyDigits.slice(7, onlyDigitsLength)}`;
    if (onlyDigitsLength < 12) return `+7(${onlyDigits.slice(1, 4)})-${onlyDigits.slice(4, 7)}-${onlyDigits.slice(7, 9)}-${onlyDigits.slice(9, onlyDigitsLength)}`;
    else return `+7(${onlyDigits.slice(1, 4)})-${onlyDigits.slice(4, 7)}-${onlyDigits.slice(7, 9)}-${onlyDigits.slice(9, 11)}`;
}

export default ProfileFieldsInputField;