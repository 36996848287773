import styles from "./profileFieldsSocialMediaField.module.css"
import {useState} from "react";
import ChangeSocialMediaPopup from "./changeSocialMediaPopup/ChangeSocialMediaPopup";

const ProfileFieldsSocialMediaField = () => {

    const [popupShow, setPopupShow] =useState(false);

    const togglePopup = () => {
        console.log(popupShow);
        setPopupShow(!popupShow);
    }

    return (
        <div className={styles.container}>
            <span className={styles.label}>Социальные сети</span>
            <div className={styles.content}>
                <span className={styles.socialMediaList}>Не указаны</span>
                <button className={styles.addSocialMedia} onClick={togglePopup}>Указать</button>
            </div>
            {popupShow && <ChangeSocialMediaPopup onCancel={togglePopup}/>}
        </div>
    )
}

export default ProfileFieldsSocialMediaField;