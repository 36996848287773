import axios from "axios";
import {accountDataUrl} from "../utils/apiUrls";

export const postProfileField = (fieldName, value) => {

    const fieldInfo = {[fieldName]: value};
    const authToken = localStorage.getItem("authorizationToken")
    return axios.post(
        accountDataUrl,
        fieldInfo,
        {headers: {Authorization: `Token ${authToken}`}}
    ).then(
        value => {
            if (value.status / 100 !== 2) {
                console.error(`error when sending profile field, status code: ${value.status}`);
                return value.statusText;
            }
            return value.statusText;
        },
        error => error.statusText
    );

}

export const getProfileData = () => {

    const authToken = localStorage.getItem("authorizationToken")
    return axios.get(
        accountDataUrl,
        {headers: {Authorization: `Token ${authToken}`}}
    );

}