import styles from "./navBar.module.css"
import NavBarImage from "../iconsAndImages/navBarLeftSideImage/NavBarImage";
import LogoutIcon from "../iconsAndImages/logoutIcon/LogoutIcon";
import { Link } from "react-router-dom";
import NotificationIcon from "../iconsAndImages/notificationIcon/NotificationIcon";
import FAQIcon from "../iconsAndImages/faqIcon/FAQIcon";

const NavBar = ({ hideLogo }) => {

    let userType = localStorage.getItem("type_user");

    return (
        <div className={styles.container}>

            {!hideLogo && (<NavBarImage />)}

            <div className={styles.linksPanel}>
                <div className={styles.mainNavigation}>
                    <div className={styles.mainNavigationLink}>
                        <span className={styles.navigationLinkSpan}>Торговая площадь</span>
                    </div>
                    <Link className={styles.link} to={`/articles`}>
                        <div className={styles.mainNavigationLink}>
                            <span className={styles.navigationLinkSpan}>Городская газета</span>
                        </div>
                    </Link>
                </div>

                <div className={styles.profileMenu}>
                    <div className={styles.smallIconBlock}>
                        <Link to={`../faq`}>
                            <FAQIcon />
                        </Link>
                    </div>
                    <Link className={styles.link} to={userType === "Соискатель" ? `/vacancy-search` : `/resume-search`}>
                        <div className={styles.profileMenuLink}>
                            <span className={styles.navigationLinkSpan}>Поиск</span>
                        </div>
                    </Link>
                    <Link className={styles.link} to={"/profile"}>
                        <div className={styles.profileMenuLink}>
                            <span className={styles.navigationLinkSpan}>Личный кабинет</span>
                        </div>
                    </Link>
                    <div className={styles.smallIconBlock}>
                        <Link to={`../notifications`}>
                            <NotificationIcon />
                        </Link>
                    </div>
                    <div className={styles.smallIconBlock}>
                        <LogoutIcon />
                    </div>
                </div>
            </div>

        </div>
    )
};

export default NavBar;