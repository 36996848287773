// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tariffs_container__1Vex4 {\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    column-gap: 5rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/tariffs/tariffs.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".container {\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    column-gap: 5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "tariffs_container__1Vex4"
};
export default ___CSS_LOADER_EXPORT___;
