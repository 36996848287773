import styles from "./resumeHeader.module.css";
import {useEffect, useState} from "react";
import {getProfileData} from "../../../../api/ProfileApi";

const ResumeHeader = () => {

    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        additionalName: "",
    });

    useEffect(() => {
        getProfileData().then(
            response => {
                if (response.statusText === "OK")
                    setUserData({...response.data})
            },
            //TODO: handle errors
            () => console.log("error while fetching user data")
        )
    }, []);

    return (
        <div className={styles.container}>
            <h2 className={styles.pageHeader}>
                Мое резюме
            </h2>
            <p className={styles.fullName}>
                {`${userData.lastName} ${userData.firstName} ${userData.additionalName}`}
            </p>
        </div>
    )
};

export default ResumeHeader;