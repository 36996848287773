import styles from "./vacancySalary.module.css";
import InputField from "../../../../components/inputField/InputField";
import Checkbox from "../../../../components/checkbox/Checkbox";

const VacancySalary = ({salaryFrom, salaryTo, salaryNotStated, onSalaryDataChange}) => {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.inputsBlock}>
                    <InputField
                        type={"number"} label={"От"} placeholder={"Минимальный уровень"}
                        value={salaryFrom}
                        required={true}
                        pattern={`\d+`}
                        invalidInputMessage={"Введите неотрицтельное целое число"}
                        onChange={onSalaryDataChange("salaryFrom")}
                        disabled={salaryNotStated}
                    />
                    <InputField
                        type={"number"} label={"До"} placeholder={"Максимальный уровень"}
                        required={true}
                        value={salaryTo}
                        invalidInputMessage={"Введите неотрицтельное целое число"}
                        pattern={`\d+`}
                        onChange={onSalaryDataChange("salaryTo")}
                        disabled={salaryNotStated}
                    />
                    <Checkbox
                        label={"Не указывать"}
                        checked={salaryNotStated}
                        onChange={onSalaryDataChange("salaryNotStated")}
                    />
                </div>
            </div>
        </div>
    )
};

export default VacancySalary;