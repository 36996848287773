import styles from "./resumeButtons.module.css";
import { Link } from "react-router-dom";

const ResumeButtons = () => {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <button className={styles.button}>Экспорт резюме в PDF</button>
                <Link to={"/profile"}>
                    <button className={styles.button}>В личный кабинет</button>
                </Link>
                <Link to={"/vacancy-search"}>
                    <button className={styles.button}>К поиску вакансий</button>
                </Link>
            </div>
        </div>
    )
};

export default ResumeButtons;