import styles from "./vacancyListPage.module.css";
import NavBar from "../../components/navBar/NavBar";
import HeaderBlock from "./headerBlock/HeaderBlock";
import MainContolsBlock from "./mainControlsBlock/MainContolsBlock";
import VacancyListBlock from "./vacancyListBlock/VacancyListBlock";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import { getVacancies, deleteVacacyById } from "./../../api/VacancyApi";
import { useEffect, useState } from "react";

const VacancyListPage = () => {
	
	const [vacancyListData, setVacancyListData] = useState([])

	useEffect(() => {
		getVacancies()
		.then(data => {
			if (data !== null) {
				setVacancyListData(data);
			}
		})
	}, []);

	const onVacancyRemove = id => removeVacancy(id, setVacancyListData);
	
	return (
		<div className={styles.container}>

			<NavBar />

			<div className={styles.content}>

				<HeaderBlock />

				<MainContolsBlock />

				<VacancyListBlock vacancyList={vacancyListData} onVacancyRemove={onVacancyRemove} />

			</div>

			<FooterImage />

		</div>
	)
};

const removeVacancy = (vacancyId, vacanciesSetter) => {
	deleteVacacyById(vacancyId)
		.then(data => {
			if (data.data !== null) {
				vacanciesSetter(data.data);
			} else {
				console.error("error while removing the vacancy!")
			}
		})
} 

export default VacancyListPage;