// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".deleteButton_container__\\+1H7A {\n\n}\n\n.deleteButton_content__E3QsA {\n\n}\n\n.deleteButton_button__OBPln {\n    background: none;\n    border: none;\n    padding: 0;\n    font: inherit;\n    cursor: pointer;\n    outline: inherit;\n\n    font-size: 1.1rem;\n    font-weight: 400;\n    transition: 0.3s;\n\n    color: #A5D7E2;\n}\n\n.deleteButton_button__OBPln:hover {\n    transition: 0.3s;\n    color: #35899B;\n}", "",{"version":3,"sources":["webpack://./src/components/deleteButton/deleteButton.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;AAEA;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,aAAa;IACb,eAAe;IACf,gBAAgB;;IAEhB,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;;IAEhB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".container {\n\n}\n\n.content {\n\n}\n\n.button {\n    background: none;\n    border: none;\n    padding: 0;\n    font: inherit;\n    cursor: pointer;\n    outline: inherit;\n\n    font-size: 1.1rem;\n    font-weight: 400;\n    transition: 0.3s;\n\n    color: #A5D7E2;\n}\n\n.button:hover {\n    transition: 0.3s;\n    color: #35899B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "deleteButton_container__+1H7A",
	"content": "deleteButton_content__E3QsA",
	"button": "deleteButton_button__OBPln"
};
export default ___CSS_LOADER_EXPORT___;
