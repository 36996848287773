import styles from "./vacancyEditPage.module.css";
import NavBar from "../../components/navBar/NavBar";
import VacancyPublishHeader from "./../vacancyPublishPage/components/vacancyPublishHeader/VacancyPublishHeader";
import GeneralVacancyInfo from "./../vacancyPublishPage/components/generalVacancyInfo/GeneralVacancyInfo";
import VacancyIsActiveBlock from "./../vacancyPublishPage/components/vacancyIsActiveBlock/VacancyIsActiveBlock";
import VacancyDescription from "./../vacancyPublishPage/components/vacancyDescription/VacancyDescription";
import CompetenciesBlock from "./../../components/competenciesBlock/CompetenciesBlock";
import VacancyRegion from "./../vacancyPublishPage/components/vacancyRegion/VacancyRegion";
import VacancySalary from "./../vacancyPublishPage/components/vacancySalary/VacancySalary";
import VacancyAdditionalInfo from "./../vacancyPublishPage/components/vacancyAdditionalInfo/VacancyAdditionalInfo";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import { Link, useParams } from "react-router-dom";
import { emptyVacancyData } from "../../utils/constants";
import { putVacansy, getVacacyById } from "../../api/VacancyApi";
import { useEffect, useState } from "react";


const VacancyEditPage = () => {

	const params = useParams();

	useEffect(() => {
		getVacacyById(params.id)
			.then(data => {
				if (data !== null) {
					setPageFields(data)
				}
			});
	}, []);

	const [pageFields, setPageFields] = useState(emptyVacancyData);

	const onFieldChange = fieldName => fieldValue => {
		setPageFields({ ...pageFields, [fieldName]: fieldValue });
	};

	const submitData = e => {
		e.preventDefault();
		putVacansy(pageFields, params.id);
	}

	return (
		<div className={styles.container}>

			<NavBar />

			<div className={styles.content}>

				<VacancyPublishHeader
					organizationName={pageFields.organization.name}
				/>

				<form
					className={styles.formContainer}
					onSubmit={submitData}
					onKeyDown={e => e.keyCode === 13 ? e.preventDefault() : undefined}>

					<div className={styles.formContent}>

						<GeneralVacancyInfo
							vacancyName={pageFields.title}
							onNameChange={onFieldChange("title")}
						/>

						<VacancyIsActiveBlock
							isActive={pageFields.isActive}
							onIsActiveChange={onFieldChange("isActive")}
						/>

						<VacancyDescription
							vacancyDescription={pageFields.description}
							onVacancyDescriptionChange={onFieldChange("description")}
						/>

						<VacancyRegion
							region={pageFields.region}
							onRegionChange={onFieldChange("region")}
						/>


						<CompetenciesBlock
							competencies={pageFields.competencies}
							onCompetenciesChange={onFieldChange('competencies')}
							showRemoveTag={true}
							label={"Компетенции"}
							showLabel={true}
						/>

						<VacancySalary
							salaryFrom={pageFields.salaryFrom}
							salaryTo={pageFields.salaryTo}
							salaryNotStated={pageFields.salaryNotStated}
							onSalaryDataChange={onFieldChange}
						/>

						<VacancyAdditionalInfo
							officeWork={pageFields.office}
							remoteWork={pageFields.remote}
							labWork={pageFields.lab}
							startup={pageFields.startup}
							onFieldsChange={onFieldChange}
						/>

						<div className={styles.buttonContainer}>
							<input
								type="submit"
								className={styles.saveButton}
								value={"Сохранить вакансию"}
							/>
						</div>

					</div>

				</form>

				<div className={`${styles.buttonContainer} ${styles.cancelButtonContainer}`}>
					<Link to={"/vacancy-list"} className={styles.cancelButton}>
						Вернуться к списку вакансий
					</Link>
				</div>

			</div>

			<FooterImage />

		</div>
	)
};

export default VacancyEditPage;