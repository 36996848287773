// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".playground_container__RWOkI {\n    font-size: 1rem;\n    display: flex;\n    justify-content: space-evenly;\n}", "",{"version":3,"sources":["webpack://./src/components/playground/playground.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,6BAA6B;AACjC","sourcesContent":[".container {\n    font-size: 1rem;\n    display: flex;\n    justify-content: space-evenly;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "playground_container__RWOkI"
};
export default ___CSS_LOADER_EXPORT___;
