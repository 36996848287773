import styles from "../resumeViewPage/resumeViewPage.module.css";
import NavBar from "../../components/navBar/NavBar";
import HeaderBlock from "./components/headerBlock/HeaderBlock";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage.js";
import Educations from "./components/educations/Educations";
import PreviousJobs from "./components/previousJobs/PreviousJobs";
import ResumePictureBlock from "./components/resumePictureBlock/ResumePictureBlock";
import Description from "./components/description/Description";
import CompetenciesBlock from "../../components/competenciesBlock/CompetenciesBlock.js";
import { useEffect, useState } from "react";
import { fetchResumeData } from "../../api/ResumeApi";
import { emptyMotivationData } from "../../utils/constants";
import { useParams } from "react-router-dom";

const ResumeViewPage = () => {

	const params = useParams();

	const [competencies, setCompetencies] = useState(emptyMotivationData.competences);
	const [description, setDescription] = useState(emptyMotivationData.description);
	const [edications, setEdications] = useState([]);
	const [previousJobs, setPreviousJobs] = useState([]);
	const [headerData, setHeaderData] = useState({ first_name: "", last_name: "", additional_name: "" });

	useEffect(() => {
		fetchResumeData(params.id)
			.then(value => {
				if (value.statusText === "OK") {
					setDescription(value.data.about_description);
					setCompetencies(value.data.competencies.map(comp => {return {name: comp}}));
					setEdications(value.data.education);
					setPreviousJobs(value.data.work_experience);
					setHeaderData({ first_name: value.data.profile.first_name, last_name: value.data.profile.last_name, additional_name: value.data.profile.additional_name });
				} else {
					console.error(`motivations data fetch error! Response code: ${value.status}`);
				}
			})
	}, [])

	return (
		<div className={styles.container}>

			<NavBar />

			{
				<div className={styles.content}>

					<HeaderBlock firstName={headerData.first_name} lastName={headerData.last_name} additionalName={headerData.additional_name} />

					<div className={styles.info}>
						<ResumePictureBlock />
						<Description description={description} />
					</div>

					{competencies.length > 0 ?
					<div className={styles.competenciesBlock}>
						<CompetenciesBlock
							competencies={competencies}
							onCompetenciesChange={setCompetencies}
							showRemoveTag={false}
							showInfo={localStorage.getItem("group") !== "Соискатель"} // во время логина устанавливается значение для юзера
							showLabel={true}
							label={"Компетенции"}
						/>
					</div> : null
					}

					{edications.map((ed, index) =>
					(
						<Educations
							university={ed.universityName}
							fieldOfStudy={ed.educationFaculty}
							degree={ed.universityDegree === "bachelor" ? "Бакалавр" : ed.universityDegree === "master" ? "Магистр" : "Аспирант"}
							specialization={ed.educationProfile}
							year={ed.graduationYear}
							grade={ed.averageGrade}
							showHeader={index === 0}
						/>
					)
					)}

					{previousJobs.map((prevJob, index) =>
					(
						<PreviousJobs
							company={prevJob.companyName}
							post={prevJob.jobPost}
							start={prevJob.jobBeginDate}
							responsibilities={prevJob.jobDescriptions}
							end={prevJob.currentJob ? "Настоящее время" : prevJob.jobEndDate}
							showHeader={index === 0}
						/>
					)
					)}

				</div>}

			<FooterImage />

		</div>
	)
};

export default ResumeViewPage;