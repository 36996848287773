// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textBoxListItem_container__C8LxJ {\n    border-radius: 0.5em;\n    background-color: #D9EFF4;\n    padding: 0.3em 0.5em;\n    -webkit-user-select: none;\n}\n\n.textBoxListItem_content__v0-Qx {\n    display: flex;\n    gap: .5rem;\n}\n\n.textBoxListItem_deleteButton__i4pPI {\n    position: relative;\n    top: .05em;\n    border: none;\n    background-color: transparent;\n    color: indianred;\n    font-weight: bold;\n    font-size: 1rem;\n\n    cursor: pointer;\n    transition: 0.2s;\n    \n}\n.textBoxListItem_deleteButton__i4pPI:hover {\n    color: firebrick;\n    transition: 0.2s;\n}\n\n.textBoxListItem_label__cQY00 {\n    color: #35899B;\n        font-family: Roboto, sans-serif;\n        font-size: 1em;\n        list-style: none;\n}", "",{"version":3,"sources":["webpack://./src/components/textboxListItem/textBoxListItem.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,yBAAyB;IACzB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,6BAA6B;IAC7B,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;;IAEf,eAAe;IACf,gBAAgB;;AAEpB;AACA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;QACV,+BAA+B;QAC/B,cAAc;QACd,gBAAgB;AACxB","sourcesContent":[".container {\n    border-radius: 0.5em;\n    background-color: #D9EFF4;\n    padding: 0.3em 0.5em;\n    -webkit-user-select: none;\n}\n\n.content {\n    display: flex;\n    gap: .5rem;\n}\n\n.deleteButton {\n    position: relative;\n    top: .05em;\n    border: none;\n    background-color: transparent;\n    color: indianred;\n    font-weight: bold;\n    font-size: 1rem;\n\n    cursor: pointer;\n    transition: 0.2s;\n    \n}\n.deleteButton:hover {\n    color: firebrick;\n    transition: 0.2s;\n}\n\n.label {\n    color: #35899B;\n        font-family: Roboto, sans-serif;\n        font-size: 1em;\n        list-style: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "textBoxListItem_container__C8LxJ",
	"content": "textBoxListItem_content__v0-Qx",
	"deleteButton": "textBoxListItem_deleteButton__i4pPI",
	"label": "textBoxListItem_label__cQY00"
};
export default ___CSS_LOADER_EXPORT___;
