import styles from "./profileFieldsBlock.module.css"
import ProfileFieldsInputField from "./profileFieldsInputField/ProfileFieldsInputField";
import ProfileFieldsPasswordField from "./profileFieldsPasswordField/ProfileFieldsPasswordField";
import ProfileFieldsCompanyField from "./profileFieldsCompanyField/ProfileFieldsCompanyField";
import {useEffect, useState} from "react";
import {postProfileField} from "../../../api/ProfileApi";

const ProfileFieldsBlockHR = ( {initData} ) => {

    const handleChange = fieldName => fieldValue => {
        setFields({...fields, [fieldName]: fieldValue});
    }
    const handleInitDataChange = fieldName => fieldValue => {
        setInitFields({...initFields, [fieldName]: fieldValue});
    }
    const onFieldInfoSubmit = fieldName => fieldValue => {
        return postProfileField(fieldName, fieldValue);
    }

    const [fields, setFields] = useState(initData);
    const [initFields, setInitFields] = useState(initData);

    useEffect(() => {
        setFields(initData);
        setInitFields(initData);
    }, [initData]);

    return (
        <div className={styles.container}>
            <div className={styles.personalFieldsBlock}>
                <ProfileFieldsInputField
                    type={"text"} label={"Фамилия"}
                    value={fields.lastName}
                    placeholder={"Введите фамилию"}
                    initialValue={initFields.lastName}
                    onChange={handleChange("lastName")}
                    onInitDataChange={handleInitDataChange("lastName")}
                    onProfileInfoSent={onFieldInfoSubmit("lastName")}
                    pattern={`^[A-Za-zА-Яа-я]{2,}$`}
                    required={true}
                    invalidInputMessage={"Фамилия должна содержать не менее двух букв и не содержать цифр"}
                />
                <ProfileFieldsInputField
                    type={"text"} label={"Имя"}
                    value={fields.firstName}
                    placeholder={"Введите имя"}
                    initialValue={initFields.firstName}
                    onChange={handleChange("firstName")}
                    onInitDataChange={handleInitDataChange("firstName")}
                    onProfileInfoSent={onFieldInfoSubmit("firstName")}
                    pattern={"^[A-Za-zА-Яа-я]{2,}$"}
                    invalidInputMessage={"Имя должно содержать не менее двух букв и не содержать цифр"}
                />
                <ProfileFieldsInputField
                    type={"text"} label={"Отчество"}
                    value={fields.additionalName}
                    placeholder={"Введите отчетсво"}
                    initialValue={initFields.additionalName}
                    onChange={handleChange("additionalName")}
                    onInitDataChange={handleInitDataChange("additionalName")}
                    onProfileInfoSent={onFieldInfoSubmit("additionalName")}
                    pattern={"^[A-Za-zА-Яа-я]{2,}$"}
                    invalidInputMessage={"Отчество должно содержать не менее двух букв и не содержать цифр"}
                />
                <ProfileFieldsInputField
                    type={"text"} label={"Город"}
                    value={fields.city}
                    placeholder={"Введите город"}
                    initialValue={initFields.city}
                    onChange={handleChange("city")}
                    onInitDataChange={handleInitDataChange("city")}
                    onProfileInfoSent={onFieldInfoSubmit("city")}
                    //todo: city pattern and validation
                    invalidInputMessage={"Такого города нет"}
                />
            </div>
            <div className={styles.contactFieldsAndPasswordBlock}>
                <ProfileFieldsInputField
                    type={"email"} label={"Email"}
                    value={fields.email}
                    placeholder={"Введите email"}
                    initialValue={initFields.email}
                    onChange={handleChange("email")}
                    onInitDataChange={handleInitDataChange("email")}
                    onProfileInfoSent={onFieldInfoSubmit("email")}
                    pattern = {`^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|.(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$`}
                    invalidInputMessage={"Неверный формат электронной почты"}
                />
                <ProfileFieldsInputField
                    type={"tel"} label={"Телефон"}
                    value={fields.phoneNumber}
                    placeholder={"Введите телефон"}
                    initialValue={initFields.phoneNumber}
                    onChange={handleChange("phoneNumber")}
                    onInitDataChange={handleInitDataChange("phoneNumber")}
                    onProfileInfoSent={onFieldInfoSubmit("phoneNumber")}
                    pattern={`^(\\+7\\([0-9]{3}\\)-[0-9]{3}-[0-9]{2}-[0-9]{2})|(\\+7)$`}
                    invalidInputMessage={"Телефон заполнен неверно"}
                    infoMessage={"Важно! В данный момент мы не проверяем телефон, так что внимательно следите за корректностью ввода"}
                />
                <ProfileFieldsPasswordField />
                <ProfileFieldsCompanyField companyName={initData.companyName} />
            </div>
        </div>
    )
}

export  default ProfileFieldsBlockHR;