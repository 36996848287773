import notificationsIcon from "../../../img/notifications-icon.svg"
import styles from "./notificationIcon.css"

const NotificationIcon = () => {
    return(
        <div>
            <img className={styles.icon} src={notificationsIcon} alt={"notifications"} />
        </div>
    )
}

export default NotificationIcon;