import styles from "./headerBlock.module.css";
import {useEffect, useState} from "react";
import {getProfileData} from "../../../../api/ProfileApi";
import {Link} from "react-router-dom";

const HeaderBlock = ( {firstName, lastName, additionalName} ) => {

    return (
        <div className={styles.container}>
            <div className={styles.centerBlock}> 
                <h2 className={styles.pageHeader}>
                    Резюме
                </h2>
                <p className={styles.fullName}>
                    {`${lastName} ${firstName} ${additionalName}`}
                </p>
            </div>
        
            <Link to={""}>
                <button className={styles.button}>Оффер</button>
            </Link>
        </div>

    )
};

export default HeaderBlock;