import { getApiCall } from "./apiCommon";
import { allArticlesListUrl, articleUrl } from "../utils/apiUrls";

export const getArticles = () => {

	const authToken = localStorage.getItem("authorizationToken");
	const config = { headers: { Authorization: `Token ${authToken}` } };
	return getApiCall(allArticlesListUrl, config)
		.then(value => {
			if (value.statusText === "OK") {
				return value.data.data.map(article => { 
					return { 
						title: article.title,
						subtitle: article.sub_title, 
						id: article.id, 
						description: article.description, 
						images: article.articleimage_set, 
						createdAt: article.created_at } })
					.sort((a,b) => a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0);
			} else {
				console.error("error while getting articles list");
				return null;
			}
		});

}

export const getArticle = (id) => {

	const authToken = localStorage.getItem("authorizationToken");
	const config = { headers: { Authorization: `Token ${authToken}` } };
	return getApiCall(articleUrl.replace("$1", `${String(id)}`), config)
		.then(value => {
			if (value.statusText === "OK") {
				return {
					...value.data,
					images: value.data.articleimage_set.sort((a,b) => a.order > b.order ? -1 : 1).map(img => img.image),
					subtitle: value.data.sub_title,
					createdAt: value.data.created_at.split("T").slice(0,1)[0],

				}
			} else {
				console.error("error while getting articles list");
				return null;
			}
		});

}