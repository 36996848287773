import styles from "./gradesBlock.module.css";
import Checkbox from "../../../../components/checkbox/Checkbox";
import { useState } from "react";

const GradesBlock = ({ gradesSetter }) => {
    
    const initState = {
        none: false,
        payment: false,
        distance: false,
        startup: false,
        city: "" // Добавляем состояние для выбора города
    };

    const [gradesSelected, setGradesSelected] = useState(initState);

    const handleChange = gradeName => gradeValue => {
        let newGrades;
        gradeName === "none" && gradeValue === true
            ? newGrades = { ...initState, none: true }
            : newGrades = { ...gradesSelected, [gradeName]: gradeValue }
        setGradesSelected(newGrades);
        // if none - return empty array what means no filters is not active. otherwise return array with true value
        gradesSetter(newGrades.none ? [] : Object.entries(newGrades).filter(entry => entry[1]).map(entry => entry[0]));
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.labelContainer}>
                    <p className={styles.label}>Обязательные характеристики работы (можно выбрать несколько):</p>
                </div>

                <div className={styles.checkboxes}>
                    <Checkbox
                        label={"Не важны"}
                        checked={gradesSelected.none}
                        onChange={handleChange("none")}
                        disabled={false}
                    />
                    <Checkbox
                        label={"Оплачиваемая"}
                        checked={gradesSelected.payment}
                        onChange={handleChange("payment")}
                        disabled={Boolean(gradesSelected.none)}
                    />
                    <Checkbox
                        label={"Удалёнка"}
                        checked={gradesSelected.distance}
                        onChange={handleChange("distance")}
                        disabled={Boolean(gradesSelected.none)}
                    />
                    <Checkbox
                        label={"Участие в стартапе"}
                        checked={gradesSelected.startup}
                        onChange={handleChange("startup")}
                        disabled={Boolean(gradesSelected.none)}
                    />

                </div>

            </div>
        </div>
    );
};

export default GradesBlock;
