// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profileFieldsBlock_container__2XJnD {\n    flex-basis: 45%;\n    display: flex;\n    justify-content: center;\n\n    gap: 10%;\n}\n\n.profileFieldsBlock_personalFieldsBlock__3B4L4 {\n    width: 45%;\n    display: flex;\n    flex-flow: column;\n}\n\n.profileFieldsBlock_contactFieldsAndPasswordBlock__K5Q8S {\n    width: 45%;\n    display: flex;\n    flex-flow: column;\n}", "",{"version":3,"sources":["webpack://./src/components/profileContent/profileFieldsBlock/profileFieldsBlock.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;;IAEvB,QAAQ;AACZ;;AAEA;IACI,UAAU;IACV,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".container {\n    flex-basis: 45%;\n    display: flex;\n    justify-content: center;\n\n    gap: 10%;\n}\n\n.personalFieldsBlock {\n    width: 45%;\n    display: flex;\n    flex-flow: column;\n}\n\n.contactFieldsAndPasswordBlock {\n    width: 45%;\n    display: flex;\n    flex-flow: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "profileFieldsBlock_container__2XJnD",
	"personalFieldsBlock": "profileFieldsBlock_personalFieldsBlock__3B4L4",
	"contactFieldsAndPasswordBlock": "profileFieldsBlock_contactFieldsAndPasswordBlock__K5Q8S"
};
export default ___CSS_LOADER_EXPORT___;
