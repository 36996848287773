import styles from "./vacancyCompetention.module.css";
import {useState} from "react";
import CompetenciesBlock from "../../../../components/competenciesBlock/CompetenciesBlock";

const VacancyCompetention = ({ initCompetences}, showRemoveTag) => {

    const [competences, setCompetences] = useState(initCompetences);
    const [autofillList, setAutofillList] = useState(["Java", "Phyton", "Дизайн", "CSS", "HTML", "Angular", "Vue", "React", "JS", "Django"]);

    return (
        <div className={styles.container}>
            <div className={styles.content}>

                {/* <CompetenciesBlock 
                    competencies={competences} 
                    onCompetenciesChange={setCompetences}
                    autofillList={autofillList} 
                    showRemoveTag={showRemoveTag} 
                    showInfo={true}
                    showLabel={true}
                /> */}

            </div>
        </div>
    )
};

export default VacancyCompetention;