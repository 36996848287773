import styles from "./profilePictureBlock.module.css"
import {useCallback} from "react";
import {useDropzone} from "react-dropzone";

const ProfilePictureBlock = () => {
    return (
        <div className={styles.container}>
            <ProfilePictureDropzone />
        </div>
    )
};

function ProfilePictureDropzone() {
    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div className={styles.dropzoneBlock} {...getRootProps()}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p className={styles.dropzoneBlockSpan}>Отпускай!</p> :
                    <div className={styles.dropzoneBlockSpan}>
                        <p>Фото не установлено</p>
                        <p>Перетащите фотографию</p>
                        <p>Либо кликните для выбора</p>
                    </div>

            }
        </div>
    )
}

export default ProfilePictureBlock;