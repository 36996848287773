import styles from "./educations.module.css";

const Educations = ({ university,fieldOfStudy, degree,specialization, year, grade,showHeader }) => {  
    return (
        <div className={styles.container}>
            {showHeader && <h2 className={styles.header}>Образование</h2>}
            
            {!showHeader && <div className={styles.divideLine}></div>}

            <div className={styles.educationtable}>
                <div className={styles.educationColumn}>
                    <div className={styles.educationHeader}>Вуз</div>
                    {!university ? <div className={styles.noData}>нет данных</div> : <div className={styles.educationContent}>{university}</div>}


                    <div className={styles.educationHeader}>Направление</div>
                    {!fieldOfStudy ? <div className={styles.noData}>нет данных</div> : <div className={styles.educationContent}>{fieldOfStudy}</div>}


                </div>
                <div className={styles.educationColumn}>
                    <div className={styles.educationHeader}>Уровень образования</div>
                    {!degree ? <div className={styles.noData}>нет данных</div> : <div className={styles.educationContent}>{degree}</div>}


                    <div className={styles.educationHeader}>Профиль</div>
                    {!specialization ? <div className={styles.noData}>нет данных</div> : <div className={styles.educationContent}>{specialization}</div>}

                </div>
                <div className={styles.educationColumn}>
                    <div className={styles.educationHeader}>Год окончания</div>
                    {!year ? <div className={styles.noData}>нет данных</div> : <div className={styles.educationContent}>{year}</div>}

                    <div className={styles.educationHeader}>Средний балл</div>
                    {!grade ? <div className={styles.noData}>нет данных</div> : <div className={styles.educationContent}>{grade}</div>}

                </div>
            </div>
        </div>
    )
};

export default Educations;