// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tariffsOrganization_container__Xwx7X {\n    margin-top: 3rem;\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    gap: 4rem;\n}\n\n.tariffsOrganization_cardsBlock__7TyQQ {\n    display: flex;\n    flex-flow: row;\n    gap: 5rem;\n}\n\n.tariffsOrganization_headlineBlock__7C9jY {\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    font-weight: 600;\n    font-size: 0.7rem;\n}", "",{"version":3,"sources":["webpack://./src/pages/tariffs/tariffsOrganization/tariffsOrganization.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,cAAc;IACd,SAAS;AACb;;AAEA;IACI,+BAA+B;IAC/B,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".container {\n    margin-top: 3rem;\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    gap: 4rem;\n}\n\n.cardsBlock {\n    display: flex;\n    flex-flow: row;\n    gap: 5rem;\n}\n\n.headlineBlock {\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    font-weight: 600;\n    font-size: 0.7rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "tariffsOrganization_container__Xwx7X",
	"cardsBlock": "tariffsOrganization_cardsBlock__7TyQQ",
	"headlineBlock": "tariffsOrganization_headlineBlock__7C9jY"
};
export default ___CSS_LOADER_EXPORT___;
