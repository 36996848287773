import {postApiCall, getApiCall} from "./apiCommon";
import {companyDataUrl} from "../utils/apiUrls"

export const getCompanyData = () => {
	const authToken = localStorage.getItem("authorizationToken")
	const config = {headers: {Authorization: `Token ${authToken}`}}
	return getApiCall(companyDataUrl, config);
}

export const postCompanyData = (companyData) => {
	const authToken = localStorage.getItem("authorizationToken");
	const body = companyData;
	const config = {headers: {Authorization: `Token ${authToken}`}}
	return postApiCall(companyDataUrl, body, config);
}