import styles from "./vacancySearchPage.module.css";
import NavBar from "../../components/navBar/NavBar";
import PageHeader from "./components/pageHeader/PageHeader";
import CompetenciesBlock from "../../components/competenciesBlock/CompetenciesBlock"
import GradesBlock from "./components/gradesBlock/GradesBlock";
import VacancyItem from "./components/vacancyItem/VacancyItem";
import InputField from "../../../src/components/inputField/InputField";
import { useState } from "react";
import { getVacancyItems } from "../../api/VacancySearchApi";
import PageCounter from "./components/pageCounter/PageCounter";

const VacancySearchPage = () => {

    const [competencies, setCompetencies] = useState([]);
    const [grades, setGrades] = useState([]);
    const [city, setCity] = useState("");
    const [vacancyItems, setVacancyItems] = useState(undefined);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handleSubmit = pageNumber => {
        setHasSubmitted(true);
        getVacancyItems(competencies.map(c => c.name), grades, city, pageNumber)
            .then(value => {
                if (value.statusText === "OK") {
                    setTotalPages(value.data.totalPages);
                    setCurrentPageNumber(value.data.currentPage);
                    setVacancyItems(value.data.vacancyItems);
                } else {
                    // color the button in red
                }
            });
    }

    return (
        <div className={styles.container}>
            <NavBar />
            <div className={styles.content}>
                <PageHeader headerText={"Поиск вакансий"} motiveLabelText={"Найдите лучшую работу!"} />
                <CompetenciesBlock
                    competencies={competencies}
                    onCompetenciesChange={setCompetencies}
                    showRemoveTag={true}
                    showLabel={true}
                    label={"Какие компетенции важны в соискателе?"}
                />
                <div className={styles.gradesAndSubmit}>
                    <div className={styles.controlsBlock}>
                        <div className={styles.gradesBlock}>
                            <GradesBlock gradesSetter={setGrades} />
                        </div>
                        <div className={styles.cityBlock}>
                            <InputField
                                type={"text"} label={"Город"} placeholder={"Введите город"}
                                value={city}
                                required={false}
                                invalidInputMessage={""}
                                onChange={setCity}
                            />
                        </div>
                    </div>
                    <div className={styles.submitBlock}>
                        <button className={styles.submit} onClick={e => handleSubmit(1)}>
                            Искать!
                        </button>
                    </div>
                </div>
                {/*<SortingBlock />*/}
                <div className={styles.divideLineUpper} />
                <div className={styles.resumeItemsBlock}>
                    {!hasSubmitted
                        ? (<p>Используйте настройки поиска и найдите самые актуальные, высокооплачиваемые и инетересные вакансии на рынке!</p>)
                        : vacancyItems !== undefined
                            ? (vacancyItems.length > 0 ?
                                (<div className={styles.resumeItemsBlock}>
                                    {vacancyItems.map(item => (
                                        <VacancyItem
                                            id={item.id}
                                            vacancyName={item.vacancyName}
                                            organizationName={item.organizationName}
                                            description={item.description}
                                            competenciesList={item.competenciesList}
                                            region={item.region}
                                            isRemote={item.isRemote}
                                            isLab={item.isLab}
                                            salaryNotStated={item.salaryNotStated}
                                        />
                                    ))}
                                    {(<div>
                                        <div className={styles.divideLineLower} />
                                        <PageCounter currentPageNumber={currentPageNumber} totalPages={totalPages} onNewPageSelect={handleSubmit} />
                                    </div>)}
                                </div>)
                                : (<p>Поиск не дал результатов, попробуйте поменять фильтры!</p>)
                            )
                            : (<p>Загрузка...</p>)
                    }
                </div>
                {/*<FooterBlock />*/}
            </div>
        </div>
    )
}

export default VacancySearchPage;