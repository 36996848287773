import {
    accountPermissionsUrl,
    accountJobsUrl,
    accountEducationsUrl,
    accountMotivationUrl,
    resumeDataByIdUrl
} from "../utils/apiUrls";
import { deleteApiCall, patchApiCall, postApiCall, getApiCall } from "./apiCommon";
import { emptyEducationData, emptyJobData } from "../utils/constants";

export const fetchResumeData = (id) => {
    if (!id) {
        console.error("param \"id\" should be provided");
        return;
    }
    const url = resumeDataByIdUrl.replace("$1", `${String(id)}/`);
    const authToken = localStorage.getItem("authorizationToken");
    const config = { headers: { Authorization: `Token ${authToken}` } };
    return getApiCall(url, config);

}

export const fetchPermissionsData = () => {

    const authToken = localStorage.getItem("authorizationToken");
    const config = { headers: { Authorization: `Token ${authToken}` } };
    return getApiCall(accountPermissionsUrl, config);

}
export const postPermissionsData = (permissions) => {

    const authToken = localStorage.getItem("authorizationToken");
    const config = { headers: { Authorization: `Token ${authToken}` } };
    return postApiCall(accountPermissionsUrl, permissions, config);

}


export const fetchJobsData = () => {

    const authToken = localStorage.getItem("authorizationToken")
    const config = { headers: { Authorization: `Token ${authToken}` } }
    return getApiCall(accountJobsUrl, config)
        .then(value => {
            if (value.statusText === "OK") {
                value.data = value.data.map(addStatus);
                if (value.data.length === 0) {
                    value.data.push(emptyJobData);
                }
                return value;
            }
        });
}
export const postJobData = (jobData) => {

    const authToken = localStorage.getItem("authorizationToken");
    const { status, ...apiJobData } = jobData;
    const config = { headers: { Authorization: `Token ${authToken}` } }

    if (status === "created") {
        return postApiCall(accountJobsUrl, apiJobData, config).then(enrichValue(jobData));
    } else if (status === "deleted") {
        return deleteApiCall(`${accountJobsUrl}${apiJobData.id}/`, apiJobData, config).then(enrichValue(jobData));
    } else if (status === "edited") {
        return patchApiCall(`${accountJobsUrl}${apiJobData.id}/`, apiJobData, config).then(enrichValue(jobData));
    } else if (status === "existed") {
        console.warn(`warning! job item with status 'existed' passed in the api executor! it is incorrect behavior`);
    } else {
        console.error(`something wrong with job item status to send, current status: ${status}`);
    }

}


export const fetchEducationsData = () => {

    const authToken = localStorage.getItem("authorizationToken")
    const config = { headers: { Authorization: `Token ${authToken}` } }
    return getApiCall(accountEducationsUrl, config).then(value => {
        if (value.statusText === "OK") {
            value.data = value.data.map(addStatus);
            if (value.data.length === 0) {
                value.data.push(emptyEducationData);
            }
            return value;
        }
    });

}
export const postEducationData = (educationData) => {

    const authToken = localStorage.getItem("authorizationToken");
    const { status, ...apiEducationData } = educationData;
    const config = { headers: { Authorization: `Token ${authToken}` } }

    if (status === "created") {
        return postApiCall(accountEducationsUrl, apiEducationData, config).then(enrichValue(educationData));
    } else if (status === "deleted") {
        return deleteApiCall(`${accountEducationsUrl}${apiEducationData.id}/`, apiEducationData, config).then(enrichValue(educationData));
    } else if (status === "edited") {
        return patchApiCall(`${accountEducationsUrl}${apiEducationData.id}/`, apiEducationData, config).then(enrichValue(educationData));
    } else if (status === "existed") {
        console.warn(`warning! education item with status 'existed' passed in the api executor! it is incorrect behavior`);
    } else {
        console.error(`something wrong with education item status to send, current status: ${status}`);
    }

}

const enrichValue = prevItem => value => {
    if (value.statusText === "OK") {
        value.data = addStatus(value.data);
        value.prevData = prevItem;
        return value;
    }
    return value;
};


export const fetchMotivationData = () => {

    const authToken = localStorage.getItem("authorizationToken")
    const config = { headers: { Authorization: `Token ${authToken}` } }
    return getApiCall(accountMotivationUrl, config)
        .then(value => {
            if (value.statusText === "OK") {
                value.data.competences = value.data.competences.map(addStatus);
                value.data.competences = value.data.competences.map(competence => { return { ...competence, id: Number(competence.id) } })
                return value;
            }
        });

}
export const postMotivationData = (motivationData) => {

    const authToken = localStorage.getItem("authorizationToken");
    const config = { headers: { Authorization: `Token ${authToken}` } }
    const apiMotivationData = {
        aboutText: motivationData.aboutText,
        competences: motivationData.competences.map(competenceToApiCompetence)
    }

    return postApiCall(accountMotivationUrl, apiMotivationData, config)
        .then(value => {
            if (value.statusText === "OK") {
                value.data.competences = value.data.competences.map(addStatus);
                return value;
            }
            return value;
        });
}

const competenceToApiCompetence = (competence) => {
    if (competence.status === "deleted") {
        const { status, ...apiCompetence } = competence;
        return { ...apiCompetence, name: "" };
    }
    else if (competence.status === "created") {
        const { id, status, ...apiCompetence } = competence;
        return apiCompetence;
    }
    else if (competence.status === "existed") {
        const { status, ...apiCompetence } = competence;
        return apiCompetence;
    }
    else {
        console.error(`competence with status '${competence.status}' provided to the api executor!`);
        return competence;
    }
};

const addStatus = item => {
    return { ...item, status: "existed" };
};


