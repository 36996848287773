import styles from "./pofileContent.module.css"
import ProfilePictureBlock from "./profilePictureBlock/ProfilePictureBlock";
import ProfileFieldsBlockHR from "./profileFieldsBlock/ProfileFieldsBlockHR";
import {Link} from "react-router-dom";

const ProfileContentHR = ( {initData} ) => {

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>Личный кабинет представителя организации</h1>

            <div className={styles.content}>
                <ProfilePictureBlock />
                <ProfileFieldsBlockHR initData={initData} />
            </div>

            <div className={styles.footer}>
                <Link to={"/vacancy-list"}> {/* TODO: choose where to go  */}
                    <button className={styles.toResumeButton}>Вакансии компании</button>
                </Link>
            </div>
        </div>
    )
}

export default ProfileContentHR;