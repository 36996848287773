import styles from "./companyBlock.module.css";
import defaultVacancyPicture from "../../../../img/account-default-icon.svg"

const FirstBlock = ({ Info, Header }) => {
    return (
        <div className={styles.container}>
            <h2 className={styles.figure}>
                <img src={defaultVacancyPicture} className={styles.vacancyPhoto} alt={"vacancyPic"} />
            </h2>
            <div className={styles.block}>
                <h1 className={styles.pageHeader}>{Header}</h1>
                <h3 className={styles.square}>{Info}</h3>
            </div>
        </div>
    )
};

export default FirstBlock;
