// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".description_container__xzE53 {\n    color: #36899B; \n    display: flex; \n    flex-direction: column; \n    width: 63%;\n    word-wrap: break-word; \n}\n\n.description_header__ZfQpJ {\n    margin: 0 0 1rem 0 ;\n    font-size: 1.4rem;\n}\n\n.description_text__xIDa0 {\n    white-space:pre-wrap;\n}", "",{"version":3,"sources":["webpack://./src/pages/resumeViewPage/components/description/description.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,qBAAqB;AACzB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".container {\n    color: #36899B; \n    display: flex; \n    flex-direction: column; \n    width: 63%;\n    word-wrap: break-word; \n}\n\n.header {\n    margin: 0 0 1rem 0 ;\n    font-size: 1.4rem;\n}\n\n.text {\n    white-space:pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "description_container__xzE53",
	"header": "description_header__ZfQpJ",
	"text": "description_text__xIDa0"
};
export default ___CSS_LOADER_EXPORT___;
