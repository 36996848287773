import styles from "./profilePage.module.css"
import NavBar from "../../components/navBar/NavBar";
import ProfileContentAplicant from "../../components/profileContent/ProfileContentAplicant";
import ProfileContentHR from "../../components/profileContent/ProfileContentHR";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import { useEffect, useState } from "react";
import { accountDataUrl } from "../../utils/apiUrls";
import axios from "axios";
import { emptyProfileData } from "../../utils/constants";

const ProfilePage = () => {

    const [data, setData] = useState(emptyProfileData);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const authToken = localStorage.getItem("authorizationToken")
        axios.get(
            accountDataUrl,
            { headers: { Authorization: `Token ${authToken}` } })
            .then(value => {
                if (value.data.group === "Представитель организации") {
                    setData(dataToHrData(value.data));
                } else {
                    setData(value.data);
                }
                setIsLoaded(true);
            });
    }, []);

    return (
        <div className={styles.container}>

            <NavBar />

            {
                isLoaded
                    ? data.group === "Представитель организации"
                        ? (<ProfileContentHR initData={data} />)
                        : (<ProfileContentAplicant initData={data} />)
                    : (<p className={styles.loadingSpan} styles>Загрузка данных...</p>)
            }

            <FooterImage />
        </div>
    )

}

const dataToHrData = (data) => {
    return {
        lastName: data.lastName,
        firstName: data.firstName,
        additionalName: data.additionalName,
        city: data.city,
        email: data.email,
        phoneNumber: data.phoneNumber,
        group: data.group,
        companyName: data.organization ? data.organization.name : "",
    }
}

export default ProfilePage;