// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".previousJobItem_jobBlock__3nejP {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n}\n\n.previousJobItem_inputsBlock__q5wbk {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 90%;\n    margin-bottom: 1rem;\n}\n\n.previousJobItem_deleteButtonBlock__bE\\+uv {\n    width: 90%;\n    display: flex;\n    flex-flow: row;\n    justify-content: end;\n\n    margin-bottom: 1rem;\n    margin-top: -.5rem;\n}\n\n.previousJobItem_saveButton__pMfz6:hover {\n    background: #cfe7f3;\n}", "",{"version":3,"sources":["webpack://./src/pages/resumePage/components/previousJobs/previousJobItem/previousJobItem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,cAAc;IACd,oBAAoB;;IAEpB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".jobBlock {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n}\n\n.inputsBlock {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 90%;\n    margin-bottom: 1rem;\n}\n\n.deleteButtonBlock {\n    width: 90%;\n    display: flex;\n    flex-flow: row;\n    justify-content: end;\n\n    margin-bottom: 1rem;\n    margin-top: -.5rem;\n}\n\n.saveButton:hover {\n    background: #cfe7f3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jobBlock": "previousJobItem_jobBlock__3nejP",
	"inputsBlock": "previousJobItem_inputsBlock__q5wbk",
	"deleteButtonBlock": "previousJobItem_deleteButtonBlock__bE+uv",
	"saveButton": "previousJobItem_saveButton__pMfz6"
};
export default ___CSS_LOADER_EXPORT___;
