import styles from "./header.module.css";

const Header = ({title, subtitle, createdAt}) => {
    return (
        <div className={styles.container}>

            <div className={styles.content}>

              <h1 className={styles.pageHeader}>{title !== "" ? title : "Статья без названия"}</h1>
              <h3 className={styles.subtitle}>{subtitle !== "" ? subtitle : "Подзаголовок не указан"}</h3>
              <p className={styles.datePublished}>{createdAt !== "" ? `Дата публикации: ${createdAt}` : "Дата публикации не указана"}</p>  

            </div>

        </div>
    )
};

export default Header;