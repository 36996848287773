import styles from "./pageHeader.module.css";

const PageHeader = ({headerText, motiveLabelText}) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.header}>{headerText}</h2>
                <p className={styles.motiveLabel}>{motiveLabelText}</p>
            </div>
        </div>
    )
}

export default PageHeader;