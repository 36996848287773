import styles from "./jobPermissions.module.css";
import InputField from "../../../../components/inputField/InputField";
import {useEffect, useState} from "react";
import {fetchPermissionsData, postPermissionsData} from "../../../../api/ResumeApi";
import {emptyJobPermissionsData} from "../../../../utils/constants";

const JobPermissions = () => {

    const [permissions, setPermissions] = useState(emptyJobPermissionsData);
    const [saveButtonStatus, setSaveButtonStatus] = useState(null);

    const getSaveButtonStyle = () => {
        if (saveButtonStatus == null) return "";
        else return saveButtonStatus ? styles.saveButtonSuccessCommit : styles.saveButtonUnsuccessCommit;
    }

    const handleChange = fieldName => fieldValue => {
        setPermissions({...permissions, [fieldName]: fieldValue})
    }

    const handlePermissionsSave = () => {
        postPermissionsData(permissions)
            .then(value => {
                if (value.statusText === "OK") {
                    setSaveButtonStatus(true);
                    setTimeout(() => setSaveButtonStatus(null), 600);
                } else {
                    setSaveButtonStatus(false);
                    setTimeout(() => setSaveButtonStatus(null), 600);
                    console.error(`permissions data send error! Response code: ${value.status}`);
                }
            });
    }

    useEffect(() => {
        fetchPermissionsData()
            .then(value => {
                if (value.statusText === "OK") {
                    setPermissions(value.data);
                } else {
                    console.error(`permissions data fetch error! Response code: ${value.status}`);
                }
            })
    }, [])


    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.header}>
                    Разрешение на трудовую деятельность
                </h2>
                <p className={styles.tip}>
                    Скучный, но важный раздел...
                </p>
                <div className={styles.inputsBlock}>
                    <InputField
                        type={"text"} label={"Гражданство"} placeholder={"Введите гражданство"}
                        value={permissions.citizenship}
                        onChange={handleChange("citizenship")}
                        required={true}
                        invalidInputMessage={""}
                    />
                    <InputField
                        type={"text"} label={"Разрешение на работу"} placeholder={"Введите возможную страну работы"}
                        value={permissions.work_permit}
                        onChange={handleChange("work_permit")}
                        required={true}
                        invalidInputMessage={""}
                    />
                </div>
                <button className={`${styles.saveButton} ${getSaveButtonStyle()}`} onClick={handlePermissionsSave}>Сохранить</button>
            </div>
        </div>
    )
};

export default JobPermissions;