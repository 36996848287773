import styles from "./checkbox.module.css";

const Checkbox = ({label, checked, id, onChange, disabled}) => {

    return(
        <div className={styles.container}>
            <div className={styles.content}>
                <input className={styles.input}
                       type={"checkbox"}
                       checked={checked}
                       id={label + id}
                       onChange={e => onChange(e.target.checked)}
                       disabled={disabled}

                />
                <label className={styles.label} htmlFor={label + id}>{label}</label>

            </div>
        </div>
    );
};

export default Checkbox;