export const projectDomainUrl = process.env.REACT_APP_BACKEND_HOST;

export const loginUrl = projectDomainUrl + "/api/auth/login/";
export const logoutUrl = projectDomainUrl + "/api/auth/logout/";

export const registerUrl = projectDomainUrl + "/api/registration/";

export const accountDataUrl = projectDomainUrl + "/api/account/";
export const accountPermissionsUrl = projectDomainUrl + "/api/student/citizenship/";
export const accountJobsUrl = projectDomainUrl + "/api/student/work-experience/";
export const accountEducationsUrl = projectDomainUrl + "/api/student/education-information/";
export const accountMotivationUrl = projectDomainUrl + "/api/student/motivation/";

export const allCompetenciesListUrl = projectDomainUrl + "/api/list-competence/";
export const resumeSearchItemsUrl = projectDomainUrl + "/api/student/list-students-resume";
export const resumeDataByIdUrl = projectDomainUrl + "/api/student/list-students-resume/$1";

export const vacancySearchItemsUrl = projectDomainUrl + "/api/organization_vacancies";

export const companyDataUrl = projectDomainUrl + "/api/organization/";

export const companyVacancyUrl = projectDomainUrl + "/api/organization_vacancies/";
export const companySingleVacancyUrl = projectDomainUrl + "/api/organization_vacancies/$1";

export const allArticlesListUrl = projectDomainUrl + "/api/articles/";
export const articleUrl = projectDomainUrl + "/api/articles/$1";
