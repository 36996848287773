import styles from "./headerBlock.module.css";

const HeaderBlock = ({info,header}) => {
    return (
        <div className={styles.container}>
              <h1 className={styles.pageHeader}>{header}</h1>
              <h2 className={styles.text}>{info}</h2>
        </div>
    )
};


export default HeaderBlock;