import styles from "./gradesBlock.module.css";
import Checkbox from "../../../../components/checkbox/Checkbox";
import {useState} from "react";

const GradesBlock = ( { gradesSetter } ) => {

    const initState = {none: false, bachelor: false, master: false, aspirant: false, specialist: false}
    const [gradesSelected, setGradesSelected] = useState({none: false, bachelor: false, master: false, aspirant: false, specialist: false})

    const handleChange = gradeName => gradeValue => {
        let newGrades;
        gradeName === "none" && gradeValue === true
            ? newGrades = {...initState, none: true}
            : newGrades = {...gradesSelected, [gradeName]: gradeValue}
        setGradesSelected(newGrades);
        // if none - return empty array what means no filters is not active. otherwise return array with true value
        gradesSetter(newGrades.none ? [] : Object.entries(newGrades).filter(entry => entry[1]).map(entry => entry[0]));
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>

                <div className={styles.labelContainer}>
                    <p className={styles.label}>Уровень образования соискателя (можно выбрать несколько):</p>
                </div>

                <div className={styles.checkboxes}>
                    <Checkbox
                        label={"Не важен"}
                        checked={gradesSelected.none}
                        onChange={handleChange("none")}
                        disabled={false}

                    />
                    <Checkbox
                        label={"Бакалавр"}
                        checked={gradesSelected.bachelor}
                        onChange={handleChange("bachelor")}
                        disabled={Boolean(gradesSelected.none)}

                    />
                    <Checkbox
                        label={"Магистр"}
                        checked={gradesSelected.master}
                        onChange={handleChange("master")}
                        disabled={Boolean(gradesSelected.none)}

                    />
                    <Checkbox
                        label={"Аспирант"}
                        checked={gradesSelected.aspirant}
                        onChange={handleChange("aspirant")}
                        disabled={Boolean(gradesSelected.none)}

                    />
                    <Checkbox
                        label={"Специалист"}
                        checked={gradesSelected.specialist}
                        onChange={handleChange("specialist")}
                        disabled={Boolean(gradesSelected.none)}

                    />
                </div>
            </div>
        </div>
    )
}

export default GradesBlock;