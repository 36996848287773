// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.checkbox_container__mPZHU {\n    display: flex;\n    flex-basis: 8.5rem;\n}\n\n.checkbox_content__Gsgl- {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n\n    column-gap: .5rem;\n\n}\n\n.checkbox_input__6wOvU {\n    display: block;\n    min-width: 2.1rem;\n    min-height: 2.1rem;\n    padding: 0;\n    margin: 0;\n\n    opacity: 1;\n\n    pointer-events: initial;\n    cursor: pointer;\n\n}\n\n.checkbox_label__UmB\\+m {\n\n    color: #A5D7E2;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n    white-space: nowrap;\n\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/components/checkbox/checkbox.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,mBAAmB;;IAEnB,iBAAiB;;AAErB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,SAAS;;IAET,UAAU;;IAEV,uBAAuB;IACvB,eAAe;;AAEnB;;AAEA;;IAEI,cAAc;IACd,+BAA+B;IAC/B,eAAe;IACf,mBAAmB;;AAEvB","sourcesContent":["\n.container {\n    display: flex;\n    flex-basis: 8.5rem;\n}\n\n.content {\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n\n    column-gap: .5rem;\n\n}\n\n.input {\n    display: block;\n    min-width: 2.1rem;\n    min-height: 2.1rem;\n    padding: 0;\n    margin: 0;\n\n    opacity: 1;\n\n    pointer-events: initial;\n    cursor: pointer;\n\n}\n\n.label {\n\n    color: #A5D7E2;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n    white-space: nowrap;\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "checkbox_container__mPZHU",
	"content": "checkbox_content__Gsgl-",
	"input": "checkbox_input__6wOvU",
	"label": "checkbox_label__UmB+m"
};
export default ___CSS_LOADER_EXPORT___;
