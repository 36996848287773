// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profileFieldsPasswordField_container__dk3j6 {\n    margin-top: 0.6em;\n}\n\n.profileFieldsPasswordField_label__U0Rlz {\n    padding-left: .5em;\n    color: #A5D7E2;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n}\n\n.profileFieldsPasswordField_content__TcbHe {\n    display: flex;\n    justify-content: space-between;\n}\n\n.profileFieldsPasswordField_passwordStatus__ydoxR {\n    padding-left: .5em;\n    color: #36899B;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n}\n\n.profileFieldsPasswordField_changePassword__VEzLr {\n    background-color: white;\n    border: none;\n\n    font-size: 1rem;\n    font-weight: 600;\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    text-decoration: underline;\n\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/profileContent/profileFieldsBlock/profileFieldsPasswordField/profileFieldsPasswordField.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,YAAY;;IAEZ,eAAe;IACf,gBAAgB;IAChB,+BAA+B;IAC/B,cAAc;IACd,0BAA0B;;IAE1B,eAAe;AACnB","sourcesContent":[".container {\n    margin-top: 0.6em;\n}\n\n.label {\n    padding-left: .5em;\n    color: #A5D7E2;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n}\n\n.content {\n    display: flex;\n    justify-content: space-between;\n}\n\n.passwordStatus {\n    padding-left: .5em;\n    color: #36899B;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n}\n\n.changePassword {\n    background-color: white;\n    border: none;\n\n    font-size: 1rem;\n    font-weight: 600;\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    text-decoration: underline;\n\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "profileFieldsPasswordField_container__dk3j6",
	"label": "profileFieldsPasswordField_label__U0Rlz",
	"content": "profileFieldsPasswordField_content__TcbHe",
	"passwordStatus": "profileFieldsPasswordField_passwordStatus__ydoxR",
	"changePassword": "profileFieldsPasswordField_changePassword__VEzLr"
};
export default ___CSS_LOADER_EXPORT___;
