// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.vacancyCompetention_container__UjAXC {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 1rem;\n}\n\n.vacancyCompetention_content__NGEju {\n    width: 90%;\n\n}\n", "",{"version":3,"sources":["webpack://./src/pages/vacancyPublishPage/components/vacancyCompetention/vacancyCompetention.module.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,UAAU;;AAEd","sourcesContent":["\n.container {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    margin-bottom: 1rem;\n}\n\n.content {\n    width: 90%;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vacancyCompetention_container__UjAXC",
	"content": "vacancyCompetention_content__NGEju"
};
export default ___CSS_LOADER_EXPORT___;
