// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profilePictureBlock_container__7zByJ {\n    margin-top: 2em;\n    width: 25em;\n    height: 25em;\n    border: #A4D7E2 solid 2px;\n    border-radius: 0.5rem;\n\n    box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);\n    cursor: pointer;\n}\n\n.profilePictureBlock_dropzoneBlock__0NJRo {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n\n.profilePictureBlock_dropzoneBlockSpan__MI9cu {\n    text-align: center;\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    font-size: 0.9rem;\n}", "",{"version":3,"sources":["webpack://./src/components/profileContent/profilePictureBlock/profilePictureBlock.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,qBAAqB;;IAErB,uCAAuC;IACvC,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,+BAA+B;IAC/B,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".container {\n    margin-top: 2em;\n    width: 25em;\n    height: 25em;\n    border: #A4D7E2 solid 2px;\n    border-radius: 0.5rem;\n\n    box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);\n    cursor: pointer;\n}\n\n.dropzoneBlock {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n}\n\n.dropzoneBlockSpan {\n    text-align: center;\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    font-size: 0.9rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "profilePictureBlock_container__7zByJ",
	"dropzoneBlock": "profilePictureBlock_dropzoneBlock__0NJRo",
	"dropzoneBlockSpan": "profilePictureBlock_dropzoneBlockSpan__MI9cu"
};
export default ___CSS_LOADER_EXPORT___;
