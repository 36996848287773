import styles from "./vacancyIsActiveBlock.module.css";
import Checkbox from "../../../../components/checkbox/Checkbox";

const VacancyIsActiveBlock = ({isActive, onIsActiveChange}) => {

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.contorlsBlock}>
					<Checkbox
						label={"Вакансия активна"}
						checked={isActive}
						onChange={onIsActiveChange}
					/>
				</div>
			</div>
		</div>
	)

};

export default VacancyIsActiveBlock;