import styles from "./footerImage.module.css";
import footerImage from "../../../img/footer-img.svg";

const FooterImage = () => {
    return(
        <div className={styles.FooterImageBlock}>
            <img src={footerImage} alt={"footer"}/>
        </div>
    )
}

export default FooterImage;