import styles from "./resumeItem.module.css";
import accountDefaultPicture from "../../../../img/account-default-icon.svg";
import { Link } from 'react-router-dom';

const ResumeItem = ({ id, photoSrc, firstName, lastName, ageWithYears, competenciesList, city, currentDegree }) => {
    return (
        <Link className={styles.link} to={`/resume-view/${id}`}>
            <div className={styles.container}>
                <img src={photoSrc ? photoSrc : accountDefaultPicture} className={styles.applicantPhoto} alt={"photo"} srcFound={(!!photoSrc).toString()} />
                <div className={styles.applicantInfoSummaryBlock}>
                    <p className={styles.applicantNameAge} >
                        {`${firstName} ${lastName}, ${ageWithYears}`}
                    </p>
                    <p className={styles.applicantCompetenciesList}>
                        {
                            competenciesList.reduce(((string, competence, currentIndex) => currentIndex === competenciesList.length - 1 ? string + competence : string + competence + " | "), "")
                        }
                    </p>
                    <p className={styles.applicantCityCurrentDegree}>
                        {`${city ? city : "Город не указан"} | ${currentDegree ? currentDegree : "Образование не указано"}`}
                    </p>
                </div>
            </div>
        </Link>
    )
}

export default ResumeItem;