import welcomeImg from "../../../img/wog-welcome-img.svg";
import "./WelcomeImageBlock.css"

const WelcomeImageBlock = ()=> {
    return(
        <div className={"WelcomeImageBlock"}>
            <img className={"WelcomeImage"} src={welcomeImg} alt={"welcome"} />
        </div>
    )
}

export default WelcomeImageBlock;