import { getApiCall, postApiCall, putApiCall, deleteApiCall } from "./apiCommon";
import { companyVacancyUrl, companySingleVacancyUrl } from "../utils/apiUrls";
import { changeCurentPage } from "./routeCommon";

export const postVacansy = (vacancyFields) => {

	const body = pepareVacancyFieldsToPost(vacancyFields);
	const authToken = localStorage.getItem("authorizationToken");
	const config = { headers: { Authorization: `Token ${authToken}` } }
	return postApiCall(companyVacancyUrl, body, config)
		.then(value => {
			if (value.statusText === "OK") {
				changeCurentPage("/vacancy-list")
			} else {
				console.error(`error while new vacancy submit. request status code: ${value.status}`)
			}
		});

}

export const putVacansy = (vacancyFields, vacancyId) => {

	const authToken = localStorage.getItem("authorizationToken");
	const config = { headers: { Authorization: `Token ${authToken}` } }
	return putApiCall(companyVacancyUrl + vacancyId + "/", pepareVacancyFieldsToPut(vacancyFields), config)
		.then(value => {
			if (value.statusText === "OK") {
				changeCurentPage("/vacancy-list")
			} else {
				console.error(`error while new vacancy submit. request status code: ${value.status}`)
			}
		});

}

export const getVacancies = () => {

	const authToken = localStorage.getItem("authorizationToken");
	const config = { headers: { Authorization: `Token ${authToken}` } }
	return getApiCall(companyVacancyUrl, config)
		.then(value => {
			if (value.statusText === "OK") {
				return value.data.data.map(apiVacancy => { return { id: apiVacancy.id, vacancyName: apiVacancy.title } });
			} else {
				console.error(`error while getting vacancies list. request status code: ${value.status}`);
				return null;
			}
		});

}

export const getVacacyById = (id) => {

	const url = companySingleVacancyUrl.replace("$1", id);
	const authToken = localStorage.getItem("authorizationToken");
	const config = { headers: { Authorization: `Token ${authToken}` } }
	return getApiCall(url, config)
		.then(value => {
			if (value.statusText === "OK") {
				return value.data;
			} else {
				console.error(`error while getting vacancies list. request status code: ${value.status}`);
				return null;
			}
		});

}

export const deleteVacacyById = (id) => {

	const url = companySingleVacancyUrl.replace("$1", id);
	const authToken = localStorage.getItem("authorizationToken");
	const config = { headers: { Authorization: `Token ${authToken}` } }
	return deleteApiCall(url, null, config)
		.then(value => {
			if (value.statusText === "OK") {
				value.data.data = prepareVacancyFieldsAfterFetch(value.data.data);
				return value.data;
			} else {
				console.error(`error while getting vacancies list. request status code: ${value.status}`);
				return null;
			}
		});

}

const pepareVacancyFieldsToPost = (vacancyFields) => {

	return {
		title: vacancyFields.vacancyName,
		active: vacancyFields.isActive,
		description: vacancyFields.vacancyDescription,
		region: vacancyFields.region,
		salaryFrom: vacancyFields.salaryNotStated ? 0 : vacancyFields.salaryFrom,
		salaryTo: vacancyFields.salaryNotStated ? 0 : vacancyFields.salaryTo,
		salaryNotStated: vacancyFields.salaryNotStated,
		competencies: vacancyFields.competencies.map(competence => competence.name),
		office: vacancyFields.officeWork,
		remote: vacancyFields.remoteWork,
		lab: vacancyFields.labWork,
		startup: vacancyFields.startup,

	}

}

const pepareVacancyFieldsToPut = (vacancyFields) => {

	return {
		...vacancyFields,
		competencies: vacancyFields.competencies.map(comp => comp.name),
		organization: vacancyFields.name
	}
}

const prepareVacancyFieldsAfterFetch = (vacanciesData) => {

	return vacanciesData.map(vacancyInfo => {
		return {
			vacancyName: vacancyInfo.title,
			id: vacancyInfo.id,

		}
	})

}	