import React from 'react';
import styles from './NotFoundPage.module.css';
import { Link } from 'react-router-dom'; // чтобы не было перезагрузки
import logo from "../../img/wg-nav-icon.svg"; // Путь к изображению логотипа
import cityscape from "../../img/footer-img.svg"; // Путь к изображению городского пейзажа

const NotFoundPage = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" />
      </div>
      <div className={styles.errorMessage}>
        <h1>Упс! Кажется, страницы, которую вы ищете, не существует 😟</h1>
        <p>Попробуйте поискать получше или начать поиск с <Link to="/profile">личного кабинета</Link>.</p>
      </div>
      <div className={styles.cityscape}>
        <img src={cityscape} alt="Cityscape" />
      </div>
    </div>
  );
};

export default NotFoundPage;
