import styles from "./changeSocialMediaPopup.module.css"
import InputField from "../../../../inputField/InputField";
import {useState} from "react";

const ChangeSocialMediaPopup = ({onCancel}) => {
    const initSocialMediaData = {
        vk: "",
        telegram: "",
        ok: ""
    }

    const handleSubmit = e => {
        e.preventDefault();
    };

    const handleChange = fieldName => fieldValue => {
        setSocialMediaData({...socialMediaData, [fieldName]: fieldValue})
    }
    const [socialMediaData, setSocialMediaData] = useState(initSocialMediaData)

    return(
        <div className={styles.container}>
            <div onClick={onCancel} className={styles.overlay}/>
            <form className={styles.form} onSubmit={handleSubmit}>
                <h1 className={styles.header}>Указать соцсети</h1>
                <InputField
                    type={"text"} label={"VK"} placeholder={"Введите ссылку на свой VK"}
                    value={socialMediaData.vk}
                    pattern={`\(\(\?\:\(\?\:vk\)\\\.com\\\/\)\)\(\\w\{4,\}\)\$`}
                    invalidInputMessage={"Неверная ссылка на vk. Формат: vk.com/user-id"}
                    onChange={handleChange("vk")}
                />
                <InputField
                    type={"text"} label={"Telegram"} placeholder={"Введите ссылку на свой Telegram"}
                    value={socialMediaData.telegram}
                    pattern={`\(\(\?\:\(\?\:t\)\\\.me\\\/\)\)\(\\w\{4,\}\)\$`}
                    invalidInputMessage={"Неверная ссылка на telegram. Формат: t.me/user-id"}
                    onChange={handleChange("telegram")}
                />
                <InputField
                    type={"text"} label={"OK"} placeholder={"Введите ссылку на свои Одноклассники"}
                    value={socialMediaData.ok}
                    pattern={`\(\(\?\:\(\?\:ok\)\\\.ru\\\/\)\)\(\\w\{4,\}\)\$`}
                    invalidInputMessage={"Неверная ссылка на ok.ru. Формат: ok.ru/user-id"}
                    onChange={handleChange("ok")}
                />
                <button
                    className={styles.submitButton}
                    type={"submit"}
                >
                    Сохранить
                </button>
            </form>
        </div>
    )
}

export default ChangeSocialMediaPopup;