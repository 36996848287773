import styles from "./textBoxListItem.module.css";
import CompetencesCounters from "../competenciesBlock/components/CompetencesCounters";

const TextBoxListItem = ({ id, label, onDelete, showInfo = false, showRemoveTag = true, theoryCount = 0, practiceCount = 0 }) => {

    return (
        <div className={styles.container}>
            <div className={styles.content}>

                <span className={styles.label}>{label}</span>
                {showInfo ? 
                    <CompetencesCounters 
                        theoryCount={theoryCount} 
                        practiceCount={practiceCount} 
                    /> : null
                }
                {showRemoveTag ? 
                <div className={styles.deleteButton}
                    id={id}
                    aria-label={"delete competence button"}
                    onClick={e => onDelete(Number(e.target.id))}>
                    ×
                </div> : null}

            </div>
        </div>
    );
};

export default TextBoxListItem;