// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.header_container__ePgMv {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: start;\n\talign-items: center;\n\tcolor: #36899B;\n\tfont-family: Roboto, sans-serif;\n\n\tmargin-bottom: 2rem;\n}\n\n.header_datePublished__ziYE4 {\n\talign-items: center;\n\ttext-align: center;\n}\n\n.header_pageHeader__3oSai{\n\ttext-align: center;\n\talign-items: center;\n\tfont-size: 1.8rem;\n\tmargin-top: 2.5rem;\n}\n\n.header_subtitle__VVLyW {\n\ttext-align: center;\n\talign-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/articlePage/header/header.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,sBAAsB;CACtB,sBAAsB;CACtB,mBAAmB;CACnB,cAAc;CACd,+BAA+B;;CAE/B,mBAAmB;AACpB;;AAEA;CACC,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,mBAAmB;CACnB,iBAAiB;CACjB,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,mBAAmB;AACpB","sourcesContent":["\n.container {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: start;\n\talign-items: center;\n\tcolor: #36899B;\n\tfont-family: Roboto, sans-serif;\n\n\tmargin-bottom: 2rem;\n}\n\n.datePublished {\n\talign-items: center;\n\ttext-align: center;\n}\n\n.pageHeader{\n\ttext-align: center;\n\talign-items: center;\n\tfont-size: 1.8rem;\n\tmargin-top: 2.5rem;\n}\n\n.subtitle {\n\ttext-align: center;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "header_container__ePgMv",
	"datePublished": "header_datePublished__ziYE4",
	"pageHeader": "header_pageHeader__3oSai",
	"subtitle": "header_subtitle__VVLyW"
};
export default ___CSS_LOADER_EXPORT___;
