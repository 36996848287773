import styles from "./generalVacancyInfo.module.css";
import InputField from "../../../../components/inputField/InputField";

const GeneralVacancyInfo = ({vacancyName, onNameChange}) => {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.header}>
                    Состав вакансии
                </h2>
                <div className={styles.inputsBlock}>
                    <InputField
                        type={"text"} label={"Наименование вакансии"} placeholder={"Например: Стажер на должность Python-разработчик"}
                        value={vacancyName}
                        required={true}
                        pattern={`[\\D\\d]{5,}`}
                        invalidInputMessage={"Не менее пяти символов"}
                        onChange={onNameChange}
                    />
                </div>
            </div>
        </div> 
    )
};

export default GeneralVacancyInfo;