import styles from "./aboutApplicant.module.css";
import TextareaInput from "../../../../components/textarea/TextareaInput";
import { useState, useEffect } from "react";
import { fetchMotivationData, postMotivationData } from "../../../../api/ResumeApi";
import { emptyMotivationData } from "../../../../utils/constants";
import { getCompetenciesList } from "../../../../api/CompetenciesApi"
import CompetenciesBlock from "../../../../components/competenciesBlock/CompetenciesBlock"
const AboutApplicant = () => {

    const [aboutText, setAboutText] = useState(emptyMotivationData.aboutText);
    const [competencies, setCompetencies] = useState(emptyMotivationData.competences);
    const [saveButtonStatus, setSaveButtonStatus] = useState(null);
    const [autofillList, setAutofillList] = useState([]);

    useEffect(() => {
        getCompetenciesList()
            .then(value => {
                value.statusText === "OK" ? setAutofillList(value.data) : console.error("Something went wrong on competencies load!");
            });
    }, [])

    const getSaveButtonStyle = () => {
        if (saveButtonStatus == null) return "";
        else return saveButtonStatus ? styles.saveButtonSuccessCommit : styles.saveButtonUnsuccessCommit;
    }

    const handleAboutTextChange = text => {
        setAboutText(text);
    }

    const handleAboutSave = () => {
        const about = { aboutText, competences: competencies };
        postMotivationData(about)
            .then(value => {
                if (value.statusText === "OK") {
                    setCompetencies(value.data.competences);
                    setSaveButtonStatus(true);
                    setTimeout(() => setSaveButtonStatus(null), 600);
                } else {
                    console.error(`motivation data send error! Response code: ${value.status}`);
                }
            });
    }

    useEffect(() => {
        fetchMotivationData()
            .then(value => {
                if (value.statusText === "OK") {
                    setAboutText(value.data.aboutText);
                    setCompetencies(value.data.competences);
                } else {
                    setSaveButtonStatus(false);
                    setTimeout(() => setSaveButtonStatus(null), 600);
                    console.error(`motivations data fetch error! Response code: ${value.status}`);
                }
            })
    }, [])


    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h2 className={styles.header}>
                    Мотивационная часть
                </h2>
                <p className={styles.tip}>
                    Самое время похвалиться собой!
                </p>
                <div className={styles.inputsBlock}>
                    <TextareaInput
                        label={"О себе"}
                        value={aboutText}
                        onChange={handleAboutTextChange}
                        placeholder={"Здесь можно кратко написать работодателю, почему он должен выбрать именно тебя"}
                    />
                </div>
                <div className={styles.competencesBlock}>

                    <CompetenciesBlock
                        competencies={competencies}
                        onCompetenciesChange={setCompetencies}
                        autofillList={autofillList}
                        showRemoveTag={true}
                        showLabel={true}
                        label={"Компетенции"}
                    />

                </div>
                <button className={`${styles.saveButton} ${getSaveButtonStyle()}`} onClick={handleAboutSave}>Сохранить</button>
            </div>
        </div>
    )
};

export default AboutApplicant;