import styles from "./competenciesBlock.module.css";
import TextBoxListItem from "../textboxListItem/TextBoxListItem";
import AutoscalingTextInput from "../../components/autoscalingInput/AutoscalingTextInput";
import { setNewCompetence, setCompetenceAsDeleted } from './utils';
import { getCompetenciesList } from "../../../src/api/CompetenciesApi"
import { useEffect, useState } from "react";

const CompetenciesBlock = ({ competencies = [], onCompetenciesChange, label, showLabel = false, showInfo = false, showRemoveTag = false }) => {

    const [autofillList, setAutofillList] = useState([]);

    useEffect(() => {
        if (showRemoveTag) {
            getCompetenciesList()
                .then(value => {
                    value.statusText === "OK" ? setAutofillList(value.data) : console.error("Something went wrong on competencies load!");
                });
        }
    }, [])

    return (
        <div className={styles.container}>

            {showLabel ?
                <div className={styles.competencesBlockLabel}>

                    <span>{label}</span>

                </div> : undefined}

            <div className={styles.content}>

                <ul className={styles.competenciesList}>
                    {
                        competencies.map(competence => competence.status !== "deleted" ? (
                            <li key={competence.id} className={styles.competencesListItem}>
                                <TextBoxListItem
                                    id={competence.id}
                                    label={competence.name}
                                    showInfo={showInfo}
                                    showRemoveTag={showRemoveTag}
                                    theoryCount={competence.theoryCount ? competence.theoryCount : 0}
                                    practiceCount={competence.practiceCount ? competence.practiceCount : 0}
                                    onDelete={() => setCompetenceAsDeleted(competencies, competence.id, onCompetenciesChange)}
                                />
                            </li>
                        ) : undefined)
                    }
                    {showRemoveTag ?
                        <li className={styles.autoscalingInputListItem}>
                            <AutoscalingTextInput
                                placeholder={"Добавить"}
                                onValueSubmit={(value) => setNewCompetence(competencies, value, onCompetenciesChange)}
                                autofillList={autofillList.filter(item => competencies.find(comp => comp.name === item && comp.status !== "deleted") === undefined).splice(0, 10)}
                            />
                        </li>
                        : undefined
                    }
                </ul>

            </div>
        </div>
    )

};

export default CompetenciesBlock;