// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resumePictureBlock_container__KHVFk {\n    width: 10rem;\n    height: 10rem;\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/resumeViewPage/components/resumePictureBlock/resumePictureBlock.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;AACnB","sourcesContent":[".container {\n    width: 10rem;\n    height: 10rem;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "resumePictureBlock_container__KHVFk"
};
export default ___CSS_LOADER_EXPORT___;
