// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.headerBlock_pageHeader__HWSih{\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 32px;\n\tcolor: #36899B;\n\tfont-weight: bold;\n}\n\n.headerBlock_text__Kg8Ic{\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 32px;\n\tcolor: #35899B;\n\tfont-weight: normal;\n\tposition: relative;\n\t\n}\n\n.headerBlock_container__d2vf3{\n\tdisplay: flex;\n\tflex-flow: column wrap;\n\tjustify-content: space-around;\n\talign-items: center;\n\n}\n", "",{"version":3,"sources":["webpack://./src/pages/vacancyViewPage/components/headerBlock/headerBlock.module.css"],"names":[],"mappings":";AACA;CACC,+BAA+B;CAC/B,eAAe;CACf,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC,+BAA+B;CAC/B,eAAe;CACf,cAAc;CACd,mBAAmB;CACnB,kBAAkB;;AAEnB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,6BAA6B;CAC7B,mBAAmB;;AAEpB","sourcesContent":["\n.pageHeader{\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 32px;\n\tcolor: #36899B;\n\tfont-weight: bold;\n}\n\n.text{\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 32px;\n\tcolor: #35899B;\n\tfont-weight: normal;\n\tposition: relative;\n\t\n}\n\n.container{\n\tdisplay: flex;\n\tflex-flow: column wrap;\n\tjustify-content: space-around;\n\talign-items: center;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageHeader": "headerBlock_pageHeader__HWSih",
	"text": "headerBlock_text__Kg8Ic",
	"container": "headerBlock_container__d2vf3"
};
export default ___CSS_LOADER_EXPORT___;
