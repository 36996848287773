// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.faqIcon_icon__S4buC {\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/iconsAndImages/faqIcon/faqIcon.module.css"],"names":[],"mappings":";AACA;IACI,eAAe;AACnB","sourcesContent":["\n.icon {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "faqIcon_icon__S4buC"
};
export default ___CSS_LOADER_EXPORT___;
