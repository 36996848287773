import styles from "./vacancyAdditionalInfo.module.css";
import Checkbox from "../../../../components/checkbox/Checkbox";

const VacancyAdditionalInfo = ({officeWork, remoteWork, labWork, startup, onFieldsChange}) => {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.inputsBlock}>
                    <Checkbox
                        label={"Офис"}
                        checked={officeWork}
                        onChange={onFieldsChange("office")}
                    />
                    <Checkbox
                        label={"Удаленка"}
                        checked={remoteWork}
                        onChange={onFieldsChange("remote")}
                    />
                    <Checkbox
                        label={"Лаборатория"}
                        checked={labWork}
                        onChange={onFieldsChange("lab")}
                    />
                    <Checkbox
                        label={"Стартап"}
                        checked={startup}
                        onChange={onFieldsChange("startup")}
                    />
                </div>
            </div>
        </div>
    )
};

export default VacancyAdditionalInfo;