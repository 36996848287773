import styles from "./articlePage.module.css";
import NavBar from "../../components/navBar/NavBar";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import Header from "./header/Header";
import { getArticle } from "../../api/ArticlesApi";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ArticlePage = () => {

	const params = useParams();
	const [articleData, setArticleData] = useState(null)

	useEffect(() => {
		getArticle(params.id)
			.then(data => {
				if (data !== null) {
					setArticleData(data);
				}
			})
	}, []);

	return (
		<div className={styles.container}>

			<NavBar />
			{articleData ? (
				<div className={styles.content}>

					<Header title={articleData.title} subtitle={articleData.subtitle} createdAt={articleData.createdAt} />

					{<div dangerouslySetInnerHTML={{ __html: articleData.description }} />}

				</div>) : null}

			<FooterImage />

		</div>
	)
};

export default ArticlePage;