import styles from "./vacancyRegion.module.css";
import InputField from "../../../../components/inputField/InputField";

const VacancyRegion = ({region, onRegionChange}) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.inputsBlock}>
                    <InputField
                        type={"text"} label={"Регион"} placeholder={"Укажите регион поиска соискателей"}
                        required={true}
                        invalidInputMessage={"Укажите регион поиска"}
                        value={region}
                        onChange={onRegionChange}
                    />
                </div>
            </div>
        </div>
    )
};

export default VacancyRegion;