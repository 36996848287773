import styles from "./pageCounter.module.css";
import {pageCounterWidth} from "../../../../utils/constants";

const PageCounter = ({currentPageNumber, totalPages, onNewPageSelect}) => {

    const handleClick = pageNumber => {
        if (pageNumber !== currentPageNumber) {
            onNewPageSelect(pageNumber);
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.counterItems}>
            {
                preparePageCounterList(currentPageNumber, totalPages).map(counterItem =>
                    (
                        <div className={styles.counterItemBlock}>
                            <p className={getCounterClassName(counterItem)} onClick={e => handleClick(counterItem.number)}>
                                {counterItem.divider ? "..." : counterItem.number.toString()}
                            </p>
                        </div>
                    )
                )
            }
            </div>
        </div>
    )
}

const preparePageCounterList = (currentPageNumber, totalPages) => {
    if (totalPages < pageCounterWidth * 2 + 1) {
        return Array.from(
            Array(totalPages).keys(),
                i => { return {number: i + 1, current: i + 1 === currentPageNumber, divider: false} }
        );
    }
    if (currentPageNumber - 1 > pageCounterWidth && currentPageNumber + pageCounterWidth < totalPages ) {
        const resultStart = [{number: 1, current: false, divider: false}, {number: -1, current: false, divider: true}];
        const resultStartCenter = resultStart.concat(Array.from(Array(pageCounterWidth * 2 + 1).keys(), i => {
            return {
                number: i + currentPageNumber - pageCounterWidth,
                current: i + currentPageNumber - pageCounterWidth === currentPageNumber,
                divider: false
            }
        }));
        return resultStartCenter.concat([
            {number: -2, current: false, divider: true},
            {number: totalPages, current: false, divider: false}
        ]);
    }
    if (currentPageNumber - 1 <= pageCounterWidth) {
        const resultStart = Array.from(
            Array(currentPageNumber + pageCounterWidth).keys(),
                i => { return {number: i + 1, current: i + 1 === currentPageNumber, divider: false} }
        );
        return resultStart.concat([
            {number: -2, current: false, divider: true},
            {number: totalPages, current: false, divider: false}
        ]);
    } else {
        const resultStart = [
            {number: 1, current: false, divider: false},
            {number: -1, current: false, divider: true}
        ]
        return resultStart.concat(Array.from(
            Array(totalPages - currentPageNumber + pageCounterWidth + 1).keys(),
            i => { return {number: i + currentPageNumber - pageCounterWidth, current: i + currentPageNumber - pageCounterWidth === currentPageNumber, divider: false} }
        ));
    }
}

const getCounterClassName = counterItem => {
    let className = ""
    if (counterItem.divider) className = className + styles.counterItemTextDivider;
    else className = className + styles.counterItemText;
    return counterItem.current ? className + " " + styles.counterItemTextCurrent : className;
}

export default PageCounter;