import styles from "./resumeSearchPage.module.css";
import NavBar from "../../components/navBar/NavBar";
import PageHeader from "./components/pageHeader/PageHeader";
import CompetenciesBlock from "../../components/competenciesBlock/CompetenciesBlock"
import GradesBlock from "./components/gradesBlock/GradesBlock";
import ResumeItem from "./components/resumeItem/ResumeItem";
import { useState } from "react";
import { getResumeItems } from "../../api/ResumeSearchApi";
import PageCounter from "./components/pageCounter/PageCounter";

const ResumeSearchPage = () => {

    const [competencies, setCompetencies] = useState([]);

    const [grades, setGrades] = useState([]);
    const [resumeItems, setResumeItems] = useState(undefined);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const handleSubmit = pageNumber => {
        setHasSubmitted(true);
        getResumeItems(grades, competencies.map(c => c.name), pageNumber)
            .then(value => {
                if (value.statusText === "OK") {
                    setTotalPages(value.data.totalPages);
                    setCurrentPageNumber(value.data.currentPage);
                    setResumeItems(value.data.resumeItems);
                } else {
                    // color the button in red
                }
            });
    }

    return (
        <div className={styles.container}>
            <NavBar />
            <div className={styles.content}>
                <PageHeader headerText={"Поиск соискателей"} motiveLabelText={"Найдите лучших!"} />

                <CompetenciesBlock
                    competencies={competencies}
                    onCompetenciesChange={setCompetencies}
                    showRemoveTag={true}
                    showLabel={true}
                    label={"Какие компетенции важны в соискателе?"}
                />

                <div className={styles.gradesAndSubmit}>
                    <div className={styles.gradesBlock}>
                        <GradesBlock gradesSetter={setGrades} />
                    </div>
                    <div className={styles.submitBlock}>
                        <button className={styles.submit} onClick={e => handleSubmit(1)}>
                            Искать!
                        </button>
                    </div>
                </div>
                {/*<SortingBlock />*/}
                <div className={styles.divideLineUpper} />
                <div className={styles.resumeItemsBlock}>
                    {!hasSubmitted
                        ? (<p>Используйте настройки поиска и найдите молодых и умелых соискателей, которые идеально подходят Вашей компании!</p>)
                        : resumeItems !== undefined
                            ? (resumeItems.length > 0 ?
                                (<div className={styles.resumeItemsBlock}>
                                    {resumeItems.map(item => (
                                        <ResumeItem
                                            id={item.id}
                                            firstName={item.firstName}
                                            lastName={item.lastName}
                                            city={item.city}
                                            competenciesList={item.competenciesList}
                                            currentDegree={item.currentDegree}
                                            ageWithYears={item.ageWithYears}
                                            photoSrc={item.photoSrc}
                                        />
                                    ))}
                                    {(<div>
                                        <div className={styles.divideLineLower} />
                                        <PageCounter currentPageNumber={currentPageNumber} totalPages={totalPages} onNewPageSelect={handleSubmit} />
                                    </div>)}
                                </div>)
                            : (<p>Поиск не дал результатов, попробуйте поменять фильтры!</p>)
                            )
                            : (<p>Загрузка...</p>)
                    }
                </div>
                {/*<FooterBlock />*/}
            </div>
        </div>
    )
}

export default ResumeSearchPage;