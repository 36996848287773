import logoutIcon from "../../../img/logout-icon.svg"
import styles from "./logoutIcon.module.css"
import {logout} from "../../../api/AuthApi";

const LogoutIcon = () => {

    const logoutUser = () => {
        logout()
            .then(value => {
                if (value.statusText === "OK") {
                    window.location.href = '/login';
                } else {
                    console.error("error when try to logout. Value returned from server:");
                    console.log(value);
                }
            });
    }

    return(
        <div>
            <img className={styles.icon} src={logoutIcon} alt={"logout"} onClick={logoutUser} />
        </div>
    )
}

export default LogoutIcon