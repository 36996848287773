import styles from "./articleBlock.module.css";
import { Link } from 'react-router-dom';

const ArticleBlock = ({ title, id, subtitle }) => {

	return (
		<Link className={styles.link} to={`/article/${id}`}>
			<div className={styles.container} >

				<div className={styles.content}>

					<span className={styles.articleNameSpan}>{title}</span>

					<div className={styles.articleDescription}>
						{subtitle}
					</div>

				</div>

			</div>
		</Link>
	)
};

export default ArticleBlock;