import styles from "./deleteButton.module.css";

const DeleteButton = ({ buttonText, onDelete }) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <button
                    className={styles.button}
                    onClick={onDelete}>
                    {buttonText}
                </button>
            </div>
        </div>
    )
};

export default DeleteButton;