// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".competencesCounters_theory__BNGeR,\n.competencesCounters_container__kdpSz {\n    display: flex;\n    gap: .8rem;\n}\n\n.competencesCounters_practice__yvkop,\n.competencesCounters_theoryCnt__JC043,\n.competencesCounters_practiceCnt__-smQW {\n    color: #FFFF;\n    font-family: Roboto, sans-serif;\n    font-size: 1em;\n}\n\n.competencesCounters_counterBlock__H4fXt {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    gap: .2rem;\n}\n\n.competencesCounters_practiceImg__gNlHA {\n    height: 1.2rem;\n    transition: .3s ease;\n}\n.competencesCounters_theoryImg__ouO\\+o {\n    height: .8rem;\n    transition: .3s ease;\n}\n\n.competencesCounters_practiceImg__gNlHA:hover,\n.competencesCounters_theoryImg__ouO\\+o:hover{\n    cursor: pointer;\n    transform: scale(1.05, 1.05);\n    transition: .3s ease;\n}", "",{"version":3,"sources":["webpack://./src/components/competenciesBlock/components/competencesCounters.module.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;IACb,UAAU;AACd;;AAEA;;;IAGI,YAAY;IACZ,+BAA+B;IAC/B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,cAAc;IACd,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,oBAAoB;AACxB;;AAEA;;IAEI,eAAe;IACf,4BAA4B;IAC5B,oBAAoB;AACxB","sourcesContent":[".theory,\n.container {\n    display: flex;\n    gap: .8rem;\n}\n\n.practice,\n.theoryCnt,\n.practiceCnt {\n    color: #FFFF;\n    font-family: Roboto, sans-serif;\n    font-size: 1em;\n}\n\n.counterBlock {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    gap: .2rem;\n}\n\n.practiceImg {\n    height: 1.2rem;\n    transition: .3s ease;\n}\n.theoryImg {\n    height: .8rem;\n    transition: .3s ease;\n}\n\n.practiceImg:hover,\n.theoryImg:hover{\n    cursor: pointer;\n    transform: scale(1.05, 1.05);\n    transition: .3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theory": "competencesCounters_theory__BNGeR",
	"container": "competencesCounters_container__kdpSz",
	"practice": "competencesCounters_practice__yvkop",
	"theoryCnt": "competencesCounters_theoryCnt__JC043",
	"practiceCnt": "competencesCounters_practiceCnt__-smQW",
	"counterBlock": "competencesCounters_counterBlock__H4fXt",
	"practiceImg": "competencesCounters_practiceImg__gNlHA",
	"theoryImg": "competencesCounters_theoryImg__ouO+o"
};
export default ___CSS_LOADER_EXPORT___;
