// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.resumeHeader_container__rVm2j {\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: center;\n}\n\n.resumeHeader_pageHeader__XG4aL{\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.8rem;\n    color: #36899B;\n\n    margin-top: 2.5rem;\n}\n\n.resumeHeader_fullName__RZV95 {\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.6rem;\n    color: #36899B;\n    text-decoration: underline;\n}", "",{"version":3,"sources":["webpack://./src/pages/resumePage/components/resumeHeader/resumeHeader.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;IACnB,+BAA+B;IAC/B,iBAAiB;IACjB,cAAc;;IAEd,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,+BAA+B;IAC/B,iBAAiB;IACjB,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":["\n.container {\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: center;\n}\n\n.pageHeader{\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.8rem;\n    color: #36899B;\n\n    margin-top: 2.5rem;\n}\n\n.fullName {\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.6rem;\n    color: #36899B;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "resumeHeader_container__rVm2j",
	"pageHeader": "resumeHeader_pageHeader__XG4aL",
	"fullName": "resumeHeader_fullName__RZV95"
};
export default ___CSS_LOADER_EXPORT___;
