import styles from "./vacancyPublishPage.module.css";
import NavBar from "../../components/navBar/NavBar";
import VacancyPublishHeader from "./components/vacancyPublishHeader/VacancyPublishHeader";
import GeneralVacancyInfo from "./components/generalVacancyInfo/GeneralVacancyInfo";
import VacancyIsActiveBlock from "./components/vacancyIsActiveBlock/VacancyIsActiveBlock";
import VacancyDescription from "./components/vacancyDescription/VacancyDescription";
import VacancyCompetention from "./components/vacancyCompetention/VacancyCompetention";
import VacancyRegion from "./components/vacancyRegion/VacancyRegion";
import VacancySalary from "./components/vacancySalary/VacancySalary";
import VacancyAdditionalInfo from "./components/vacancyAdditionalInfo/VacancyAdditionalInfo";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import CompetenciesBlock from "./../../components/competenciesBlock/CompetenciesBlock";
import { Link } from "react-router-dom";
import { emptyVacancyData } from "../../utils/constants";
import { postVacansy } from "../../api/VacancyApi";
import { useState } from "react";


const VacancyPublishPage = () => {

    const [pageFields, setPageFields] = useState(emptyVacancyData);

    const onFieldChange = fieldName => fieldValue => {
        setPageFields({ ...pageFields, [fieldName]: fieldValue });
    };

    const submitData = e => {
        e.preventDefault();
        postVacansy(pageFields);
    }

    return (
        <div className={styles.container}>

            <NavBar />

            <div className={styles.content}>

                <VacancyPublishHeader />

                <form
                    className={styles.formContainer}
                    onSubmit={submitData}
                    onKeyDown={e => e.keyCode === 13 ? e.preventDefault() : undefined}
                >

                    <div className={styles.formContent}>

                        <GeneralVacancyInfo
                            vacancyName={pageFields.vacancyName}
                            onNameChange={onFieldChange("vacancyName")}
                        />

                        <VacancyIsActiveBlock
                            isActive={pageFields.isActive}
                            onIsActiveChange={onFieldChange("isActive")}
                        />

                        <VacancyDescription
                            vacancyDescription={pageFields.vacancyDescription}
                            onVacancyDescriptionChange={onFieldChange("vacancyDescription")}
                        />

                        <VacancyRegion
                            region={pageFields.region}
                            onRegionChange={onFieldChange("region")}
                        />

                        <CompetenciesBlock
                            competencies={pageFields.competencies}
                            onCompetenciesChange={onFieldChange('competencies')}
                            showRemoveTag={true}
                            label={"Компетенции"}
                            showLabel={true}
                        />

                        <VacancySalary
                            salaryFrom={pageFields.salaryFrom}
                            salaryTo={pageFields.salaryTo}
                            salaryNotStated={pageFields.salaryNotStated}
                            onSalaryDataChange={onFieldChange}
                        />

                        <VacancyAdditionalInfo
                            officeWork={pageFields.office}
                            remoteWork={pageFields.remote}
                            labWork={pageFields.lab}
                            onFieldsChange={onFieldChange}
                        />

                        <div className={styles.buttonContainer}>
                            <input
                                type="submit"
                                className={styles.publishButton}
                                value={"Опубликовать вакансию"}
                            />
                        </div>

                    </div>

                </form>

                <div className={`${styles.buttonContainer} ${styles.cancelButtonContainer}`}>
                    <Link to={"/vacancy-list"} className={styles.cancelButton}>
                        Вернуться к списку вакансий
                    </Link>
                </div>

            </div>

            <FooterImage />

        </div>
    )
};

export default VacancyPublishPage;