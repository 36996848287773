import styles from "./tariffCard.module.css";
import {Link} from "react-router-dom";

const TariffCard = ( {headlineText, active, pricing, description, linkTo, organizationCard} ) => {
    return (
        <Link className={styles.link} to={linkTo} >
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.pictureBlock}>
                        <p>Tariff picture</p>
                    </div>
                    <div className={styles.headlineTextBlockAndActive}>
                        <h3 className={styles.headlineText}>{headlineText}</h3>
                        <p className={active ? styles.tariffActive + " " + styles.tariffActiveTrue : styles.tariffActive}>
                            {active ? "Активен" : "Неактивен"}
                        </p>
                    </div>

                    <div className={styles.pricingBlock}>
                        <p className={styles.pricing}>
                            {pricing > 0 ? `От ${pricing} рублей ${ + organizationCard ? "за вакансию" : ""}` : `Бесплатно`}
                        </p>
                    </div>
                    <div className={styles.descriptionBlock}>
                        <p className={styles.description}>{description}</p>
                    </div>
                </div>
            </div>
        </Link>
    )
};

export default TariffCard;