import { getApiCall } from "./apiCommon";
import { allCompetenciesListUrl, vacancySearchItemsUrl } from "../utils/apiUrls";


export const getCompetenciesList = () => {

    const authToken = localStorage.getItem("authorizationToken");
    const config = { headers: { Authorization: `Token ${authToken}` } };
    return getApiCall(allCompetenciesListUrl, config);

}

// grades - признаки вакансии ()
export const getVacancyItems = async (competenciesNames, grades, cityName, pageNumber) => {

    const authToken = localStorage.getItem("authorizationToken");
    const config = { headers: { Authorization: `Token ${authToken}` } };
    return getApiCall(vacancySearchItemsUrl + paramsString(competenciesNames, grades, cityName, pageNumber), config)
        .then(value => {
            if (value.statusText === "OK") {
                return { statusText: "OK", data: processVacancyItems(value) };
            } else {
                console.error("something wrong when call to resumes search endpoint. status code: " + value.status);
                return value;
            }
        });
}

const processVacancyItems = apiOkValue => {
    return {
        totalPages: apiOkValue.data.total_pages,
        currentPage: apiOkValue.data.page_num,
        vacancyItems:
            apiOkValue.data.data.map(item => {
                return {
                    id: item.id,
                    vacancyName: item.title,
                    organizationName: item.organization.name,
                    description: item.description,
                    competenciesList: item.competencies.map(competence => competence.name),
                    region: item.region,
                    isRemote: item.remote,
                    isLab: item.lab,
                    salaryNotStated: item.salaryNotStated,
                }
            })
    }
}

const paramsString = (competenciesNames, grades, regionName, pageNumber) => {

    const urlParams = new URLSearchParams();

    // Добавление фильтров строку параметров (таких как удалёнка, оплачиваемость...)
    if (!grades.find(grade => grade === "none")) {
        grades.forEach(grade => {
            urlParams.append("grade", grade);
        });
    }

    // Добавление имен компетенций в строку параметров
    competenciesNames.forEach(competenceName => {
        urlParams.append("cid", competenceName);
    });

    // Добавление региона в строку параметров (если регион не пустой)
    if (regionName) {
        urlParams.append("region", regionName);
    }

    // Добавление номера страницы в строку параметров запрос (пагинцая)
    urlParams.append("page", pageNumber);

    return "?" + urlParams.toString();

}
