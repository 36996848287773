import styles from "./headerBlock.module.css";

const HeaderBlock = ({}) => {
    return (
        <div className={styles.container}>

            <div className={styles.content}>

              <h1 className={styles.pageHeader}>Список вакансий компании</h1>

            </div>

        </div>
    )
};

export default HeaderBlock;