import WelcomeImageBlock from "../../components/iconsAndImages/welcomeImage/WelcomeImageBlock";
import styles from "./registrationPage.module.css";
import RegistrationForm from "../../components/registrationForm/RegistrationForm";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import Axios from "axios";
import {registerUrl} from "../../utils/apiUrls";
import {registrationFormInitData} from "../../utils/constants"

const RegistrationPage = () => {

    const onFormSubmit = formData => {
        Axios
            .post(registerUrl, formData)
            .then(response => {
                console.log(response.data);
                if (response.status === 201) {
                    window.location.href = '/login';
                } else {
                    console.error();
                }
            })
                
               
    }

    return(
        <div className={styles.registrationPage}>
            <WelcomeImageBlock />

            <RegistrationForm initData={registrationFormInitData} onSubmit={onFormSubmit}/>

            <FooterImage />
        </div>
    )

}

export default RegistrationPage;