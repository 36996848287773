// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.vacancyListPage_container__Lfe2\\+ {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tfont-size: 1rem;\n}\n\n.vacancyListPage_content__HlDVP {\n\twidth: 85%;\n}\n\n.vacancyListPage_info__67\\+iI {\n    display: flex;\n    justify-content: start;\n    width: 100%;\n\tmargin-left: 5%;\n    gap: 5%;\n}", "",{"version":3,"sources":["webpack://./src/pages/vacancyListPage/vacancyListPage.module.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,UAAU;AACX;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;CACd,eAAe;IACZ,OAAO;AACX","sourcesContent":["\n.container {\n\tdisplay: flex;\n\tflex-direction: column;\n\talign-items: center;\n\tfont-size: 1rem;\n}\n\n.content {\n\twidth: 85%;\n}\n\n.info {\n    display: flex;\n    justify-content: start;\n    width: 100%;\n\tmargin-left: 5%;\n    gap: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vacancyListPage_container__Lfe2+",
	"content": "vacancyListPage_content__HlDVP",
	"info": "vacancyListPage_info__67+iI"
};
export default ___CSS_LOADER_EXPORT___;
