// Экспортные функции предназначены только для компонента CompetenciesBlock

export const setNewCompetence = (competencies, competenceName, onCompetenciesChange) => {
	let newCompetencies = competencies;
        const competenceByName = competencies.find(competence => competence.name === competenceName);
        if (competenceByName === undefined) {
            newCompetencies =
                [...competencies,
                {
                    id: competencies.reduce((currentValue, competence) =>
                        Number(competence.id) > currentValue ? Number(competence.id) : currentValue, 0) + 1,
                    name: competenceName,
                    status: "created"
                }
                ];
        } else if (competenceByName.status === "deleted") {
            newCompetencies = competencies
                .map(competence => {
                    if (competence.name === competenceByName.name) {
                        return { ...competence, status: 'existed' };
                    } else {
                        return competence;
                    }
                })
        }
        onCompetenciesChange(newCompetencies);	
}

export const setCompetenceAsDeleted = (competencies, competenceId, onCompetenciesChange) => {
	let newCompetencies = competencies;
        const competenceById = competencies.find(competence => competence.id === competenceId);
        newCompetencies = competenceById.status === "existed"
            ? competencies.map(competence => competence.id === competenceId
                ? { ...competence, status: "deleted" }
                : competence)
            : competencies.filter(competence => competence.id !== competenceId);

        onCompetenciesChange(newCompetencies);
}