import styles from "./tariffsOrganization.module.css"
import TariffCard from "../tariffCard/TariffCard";

const TariffsOrganizationPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.headlineBlock}>
                <h1 className={styles.headlineText}>Тарифы для компании</h1>
            </div>
            <div className={styles.cardsBlock}>
                <TariffCard
                    headlineText={"Предприятие"}
                    active={false}
                    pricing={790}
                    description={"Размещение вакансий без дополнений"}
                    organizationCard={true}
                    linkTo={`basic`}
                />
                <TariffCard
                    headlineText={"Организация"}
                    active={true}
                    pricing={1190}
                    description={"Размещение вакансий + автоподъем раз в три дня"}
                    organizationCard={true}
                    linkTo={`pro`}
                />
                <TariffCard
                    headlineText={"Корпорация"}
                    active={false}
                    pricing={2960}
                    description={"Размещение вакансий + неделя в топе вакансий"}
                    organizationCard={true}
                    linkTo={`pro-max`}
                />
            </div>

        </div>
    )
};

export default  TariffsOrganizationPage;