import styles from "./pofileContent.module.css"
import ProfilePictureBlock from "./profilePictureBlock/ProfilePictureBlock";
import ProfileFieldsBlock from "./profileFieldsBlock/ProfileFieldsBlock";
import { Link } from "react-router-dom";

const ProfileContentAplicant = ({ initData }) => {
    // TODO: проверять тип аккаутна и делать условный рендеринг кабинета

    return (
        <div className={styles.container}>
            <h1 className={styles.header}>Личный кабинет соискателя</h1>

            <div className={styles.content}>
                <ProfilePictureBlock />
                <ProfileFieldsBlock initData={initData} />
            </div>

            <div className={styles.footer}>
                <Link to={"resume"}>
                    <button className={styles.toResumeButton}>Мое резюме</button>
                </Link>
            </div>
        </div>
    )
}

export default ProfileContentAplicant;