// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vacancyListBlock_vacancyList__Wkpox {\n\tpadding: 0;\n\tlist-style: none;\n}", "",{"version":3,"sources":["webpack://./src/pages/vacancyListPage/vacancyListBlock/vacancyListBlock.module.css"],"names":[],"mappings":"AAAA;CACC,UAAU;CACV,gBAAgB;AACjB","sourcesContent":[".vacancyList {\n\tpadding: 0;\n\tlist-style: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vacancyList": "vacancyListBlock_vacancyList__Wkpox"
};
export default ___CSS_LOADER_EXPORT___;
