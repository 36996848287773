// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.resumeButtons_container__TkLjQ {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 1rem;\n    margin-bottom: 3rem;\n}\n\n.resumeButtons_content__vL\\+LA {\n    display: flex;\n    justify-content: center;\n    gap: 2rem;\n}\n\n.resumeButtons_button__mzx-r {\n    width: 15em;\n    height: 1.8em;\n    justify-content: center;\n\n    background: #D9EFF4;\n    border-radius: 0.8em;\n    border: none;\n\n    font-family: Roboto, sans-serif;\n    text-align: center;\n    font-size: 1.3rem;\n    font-weight: 600;\n\n    color: #35899B;\n    cursor: pointer;\n\n    margin-top: 1.5rem;\n\n    transition: 0.3s;\n}\n\n.resumeButtons_button__mzx-r:hover {\n    background: #cfe7f3;\n\n    transition: 0.3s;\n}", "",{"version":3,"sources":["webpack://./src/pages/resumePage/components/resumeButtons/resumeButtons.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;;IAEvB,mBAAmB;IACnB,oBAAoB;IACpB,YAAY;;IAEZ,+BAA+B;IAC/B,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;;IAEhB,cAAc;IACd,eAAe;;IAEf,kBAAkB;;IAElB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;;IAEnB,gBAAgB;AACpB","sourcesContent":["\n.container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 1rem;\n    margin-bottom: 3rem;\n}\n\n.content {\n    display: flex;\n    justify-content: center;\n    gap: 2rem;\n}\n\n.button {\n    width: 15em;\n    height: 1.8em;\n    justify-content: center;\n\n    background: #D9EFF4;\n    border-radius: 0.8em;\n    border: none;\n\n    font-family: Roboto, sans-serif;\n    text-align: center;\n    font-size: 1.3rem;\n    font-weight: 600;\n\n    color: #35899B;\n    cursor: pointer;\n\n    margin-top: 1.5rem;\n\n    transition: 0.3s;\n}\n\n.button:hover {\n    background: #cfe7f3;\n\n    transition: 0.3s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "resumeButtons_container__TkLjQ",
	"content": "resumeButtons_content__vL+LA",
	"button": "resumeButtons_button__mzx-r"
};
export default ___CSS_LOADER_EXPORT___;
