import axios from "axios";

export const postApiCall = (url, data, config) => {
    return axios.post(url, data, {...config,  validateStatus: validateStatus});
};

export const putApiCall = (url, data, config) => {
    return axios.put(url, data, {...config,  validateStatus: validateStatus});
};

export const deleteApiCall = (url, data, config) => {
    return axios.delete(url, {data: data, headers: config.headers, validateStatus: validateStatus});
}

export const patchApiCall = (url, data, config) => {
    return axios.patch(url, data, {...config,  validateStatus: validateStatus});
}

export const getApiCall = (url, config) => {
    return axios.get(url, {...config,  validateStatus: validateStatus});
}

// use this function to specify http statuses that make function above do resolve
// (now any status will resolve the promise)
const validateStatus = status => {
    return status > 0;
}