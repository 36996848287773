import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LoginPage from "./pages/loginPage/LoginPage";
import RegistrationPage from "./pages/registrationPage/RegistrationPage";
import ProfilePage from "./pages/profilePage/ProfilePage";
import TariffsApplicantPage from "./pages/tariffs/TariffsApplicantPage";
import TariffsOrganizationPage from "./pages/tariffs/TariffsOrganizationPage";
import ResumePage from "./pages/resumePage/ResumePage";
import Playground from "./components/playground/Playground";
import VacancyPublishPage from "./pages/vacancyPublishPage/VacancyPublishPage";
import VacancyEditPage from "./pages/vacancyEditPage/VacancyEditPage";
import ResumeSearchPage from "./pages/resumeSearchPage/ResumeSearchPage";
import VacancyListPage from "./pages/vacancyListPage/VacancyListPage";
import VacancyViewPage from "./pages/vacancyViewPage/VacancyViewPage";
import LendingPage from "./pages/lendingPage/LendingPage";
import ResumeViewPage from "./pages/resumeViewPage/ResumeViewPage";
import NotFoundPage from "./pages/notfoundPage/NotFoundPage"; 
import VacancySearchPage from './pages/vacancySearchPage/VacancySearchPage';
import ArticlesPage from './pages/articlesPage/ArticlesPage';
import ArticlePage from './pages/articlePage/ArticlePage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <LendingPage />,
    },
    {
        path: "/playground",
        element: <Playground />,
    },
    // Login and registration pages
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/registration",
        element: <RegistrationPage />,
    },
    // Common pages for all types of user
    {
        path: "/profile",
        element: <ProfilePage />,
    },
    // Aplicant pages
    {
        path: "/profile/resume",
        element: <ResumePage/>,
    },
    {
        path: "/tariffs-applicant",
        element: <TariffsApplicantPage/>,
    },
    // Organization pages
    {
        path: "/tariffs-organization",
        element: <TariffsOrganizationPage/>,
    },
    {
        path: "/vacancy-publish/",
        element: <VacancyPublishPage/>,
    },
    {
        path: "/vacancy/:id",
        element: <VacancyEditPage/>,
    },
    {
        path: "/resume-search",
        element: <ResumeSearchPage/>,
    },
    {
        path: "/vacancy-list",
        element: <VacancyListPage/>,
    },
    {
        path: "/vacancy-view/:id",
        element: <VacancyViewPage/>,
    },
    {
        path: "/resume-view/:id",
        element: <ResumeViewPage/>,
    },
    {
        path: "/vacancy-search",
        element: <VacancySearchPage/>,
    },
    {
        path: "/articles",
        element: <ArticlesPage/>,
    },
    {
        path: "/article/:id",
        element: <ArticlePage/>,
    },
    {
        path: "*", 
        element: <NotFoundPage/>, 
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router}/>
  // </React.StrictMode>
);
