// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pageHeader_container__XdIHy {\n\n}\n\n.pageHeader_content__-vvnX {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n\n}\n\n.pageHeader_header__wB7QB {\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.8rem;\n    color: #36899B;\n\n    margin-top: 2.5rem;\n    margin-bottom: 0;\n}\n\n.pageHeader_motiveLabel__AlQqP {\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.2rem;\n    color: #36899B;\n\n    margin-top: 1rem;\n}\n\n", "",{"version":3,"sources":["webpack://./src/pages/resumeSearchPage/components/pageHeader/pageHeader.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;;AAE/B;;AAEA;IACI,mBAAmB;IACnB,+BAA+B;IAC/B,iBAAiB;IACjB,cAAc;;IAEd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,+BAA+B;IAC/B,iBAAiB;IACjB,cAAc;;IAEd,gBAAgB;AACpB","sourcesContent":[".container {\n\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n\n}\n\n.header {\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.8rem;\n    color: #36899B;\n\n    margin-top: 2.5rem;\n    margin-bottom: 0;\n}\n\n.motiveLabel {\n    align-items: center;\n    font-family: Roboto, sans-serif;\n    font-size: 1.2rem;\n    color: #36899B;\n\n    margin-top: 1rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pageHeader_container__XdIHy",
	"content": "pageHeader_content__-vvnX",
	"header": "pageHeader_header__wB7QB",
	"motiveLabel": "pageHeader_motiveLabel__AlQqP"
};
export default ___CSS_LOADER_EXPORT___;
