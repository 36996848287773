// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textInfoBlock_header__7pyH\\+ {\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1.375rem;\n\tcolor: #36899B;\n\tfont-weight: bold;\n}\n\n.textInfoBlock_textBox__sCFAS {\n\tfont-weight: normal;\n\tfont-family: Roboto, sans-serif;\n\theight: auto;\n\tfont-size: 1.25rem;\n\tword-break: break-all;\n\tcolor: #36899B;\n\n}\n\n.textInfoBlock_container__gkxqT {\n\tdisplay: flex;\n\tflex-flow: row wrap;\n\tjustify-content: space-between;\n\n}\n\n.textInfoBlock_block__7fCtZ {\n\tflex: 1 0;\n}\n\n.textInfoBlock_alignLeft__nNjft {\n\ttext-align: left;\n}\n\n.textInfoBlock_alignRight__gU5pa {\n\ttext-align: right;\n}\n\n.textInfoBlock_alignCenter__DYhfj {\n\ttext-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/textInfoBlock/textInfoBlock.module.css"],"names":[],"mappings":"AAAA;CACC,+BAA+B;CAC/B,mBAAmB;CACnB,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC,mBAAmB;CACnB,+BAA+B;CAC/B,YAAY;CACZ,kBAAkB;CAClB,qBAAqB;CACrB,cAAc;;AAEf;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,8BAA8B;;AAE/B;;AAEA;CACC,SAAS;AACV;;AAEA;CACC,gBAAgB;AACjB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,kBAAkB;AACnB","sourcesContent":[".header {\n\tfont-family: Roboto, sans-serif;\n\tfont-size: 1.375rem;\n\tcolor: #36899B;\n\tfont-weight: bold;\n}\n\n.textBox {\n\tfont-weight: normal;\n\tfont-family: Roboto, sans-serif;\n\theight: auto;\n\tfont-size: 1.25rem;\n\tword-break: break-all;\n\tcolor: #36899B;\n\n}\n\n.container {\n\tdisplay: flex;\n\tflex-flow: row wrap;\n\tjustify-content: space-between;\n\n}\n\n.block {\n\tflex: 1 0;\n}\n\n.alignLeft {\n\ttext-align: left;\n}\n\n.alignRight {\n\ttext-align: right;\n}\n\n.alignCenter {\n\ttext-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "textInfoBlock_header__7pyH+",
	"textBox": "textInfoBlock_textBox__sCFAS",
	"container": "textInfoBlock_container__gkxqT",
	"block": "textInfoBlock_block__7fCtZ",
	"alignLeft": "textInfoBlock_alignLeft__nNjft",
	"alignRight": "textInfoBlock_alignRight__gU5pa",
	"alignCenter": "textInfoBlock_alignCenter__DYhfj"
};
export default ___CSS_LOADER_EXPORT___;
