import styles from "./resumePage.module.css";
import NavBar from "../../components/navBar/NavBar";
import ResumeHeader from "./components/resumeHeader/ResumeHeader";
import JobPermissions from "./components/jobPermissions/JobPermissions";
import Educations from "./components/educations/Educations";
import PreviousJobs from "./components/previousJobs/PreviousJobs";
import AboutApplicant from "./components/aboutApplicant/AboutApplicant";
import ResumeButtons from "./components/resumeButtons/ResumeButtons";
import {emptyMotivationData} from "../../utils/constants";

const ResumePage = () => {
    return (
        <div className={styles.container}>

            <NavBar />

            <div className={styles.content}>

                <ResumeHeader />

                <JobPermissions />

                <Educations />

                <PreviousJobs />

                <AboutApplicant />

                <ResumeButtons />

            </div>
        </div>
    )
};

export default ResumePage;