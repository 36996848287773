import faqIcon from "../../../img/faq-icon.svg"
import styles from "./faqIcon.module.css"

const FAQIcon = () => {
    return(
        <div>
            <img className={styles.icon} src={faqIcon} alt={"logout"} />
        </div>
    )
}

export default FAQIcon;