import styles from "./vacancyPublishHeader.module.css";

const VacancyPublishHeader = ({organizationName}) => {

    return (
        <div className={styles.container}>
            <h2 className={styles.pageHeader}>
                Размещение вакансии
            </h2>
            <p className={styles.fullName}>
                {organizationName}
            </p>
        </div>
    )
};

export default VacancyPublishHeader;