// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pageCounter_container__-WbXK {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.pageCounter_counterItems__mlFh1 {\n    display: flex;\n    flex-direction: row;\n    gap: .5rem;\n}\n\n.pageCounter_counterItemBlock__1\\+X3q {\n    caret-color: transparent;\n    cursor: pointer;\n\n\n}\n\n.pageCounter_counterItemText__42qkp {\n    color: #A5D7E2;\n    transition: 0.3s;\n}\n\n.pageCounter_counterItemText__42qkp:hover {\n    color: #71c0cd;\n    cursor: pointer;\n    text-decoration: underline;\n    transition: 0.3s;\n}\n\n.pageCounter_counterItemTextCurrent__h\\+5\\+f {\n    color: #35899B;\n}\n\n.pageCounter_counterItemTextDivider__mgrvh {\n    color: #71c0cd;\n    transition: 0.3s;\n    cursor: default;\n}", "",{"version":3,"sources":["webpack://./src/pages/vacancySearchPage/components/pageCounter/pageCounter.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,wBAAwB;IACxB,eAAe;;;AAGnB;;AAEA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".container {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n}\n\n.counterItems {\n    display: flex;\n    flex-direction: row;\n    gap: .5rem;\n}\n\n.counterItemBlock {\n    caret-color: transparent;\n    cursor: pointer;\n\n\n}\n\n.counterItemText {\n    color: #A5D7E2;\n    transition: 0.3s;\n}\n\n.counterItemText:hover {\n    color: #71c0cd;\n    cursor: pointer;\n    text-decoration: underline;\n    transition: 0.3s;\n}\n\n.counterItemTextCurrent {\n    color: #35899B;\n}\n\n.counterItemTextDivider {\n    color: #71c0cd;\n    transition: 0.3s;\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "pageCounter_container__-WbXK",
	"counterItems": "pageCounter_counterItems__mlFh1",
	"counterItemBlock": "pageCounter_counterItemBlock__1+X3q",
	"counterItemText": "pageCounter_counterItemText__42qkp",
	"counterItemTextCurrent": "pageCounter_counterItemTextCurrent__h+5+f",
	"counterItemTextDivider": "pageCounter_counterItemTextDivider__mgrvh"
};
export default ___CSS_LOADER_EXPORT___;
