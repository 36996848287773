import styles from "./mainContolsBlock.module.css";
import {Link} from "react-router-dom";

const MainContolsBlock = ({}) => {
	return (
		<div className={styles.container}>

			<div className={styles.content}>

				{/* maybe it would be good to create list of contorls when more controls will be needed */}
				<div className={styles.buttonBlock}>
					<Link className={styles.CreateVacancyButton} to={"/vacancy-publish"}>
						+ Создать вакансию
					</Link>
				</div>

			</div>

		</div>
	)
};

export default MainContolsBlock;