import styles from "./vacancyItem.module.css";
import defaultVacancyPicture from "../../../../img/account-default-icon.svg";
import { Link } from 'react-router-dom';

const ResumeItem = ({ id, vacancyName, organizationName, description, competenciesList, region, isRemote, isLab, salaryNotStated }) => {
    return (
        <Link className={styles.link} to={`/vacancy-view/${id}`}>
            <div className={styles.container}>
                <img src={defaultVacancyPicture} className={styles.vacancyPhoto} alt={"vacancyPic"} />
                <div className={styles.vacancyInfoSummaryBlock}>
                    <p className={styles.vacancyNameAge} >
                        {`${vacancyName} | ${organizationName}`}
                    </p>
                    <p className={styles.vacancyDescription} >
                        {`${description}`}
                    </p>
                    <p className={styles.vacancyCompetenciesList}>
                        {
                            competenciesList.reduce(((string, competence, currentIndex) => currentIndex === competenciesList.length - 1 ? string + competence : string + competence + " | "), "")
                        }
                    </p>
                    <p className={styles.vacancyCityAndAdditionalInfo}>
                        {`${region ? region : "Город не указан"}${isRemote ? " | Возможна удаленка" : ""}${isLab ? " | Работа в лаборатории" : ""}${!salaryNotStated ? " | Оплачиваемая" : ""}`}
                    </p>
                </div>
            </div>
        </Link>
    )
}

export default ResumeItem;