// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".registrationPage_registrationPage__wGii4 {\n    display: flex;\n    flex-flow: column;\n    justify-content: space-evenly;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://./src/pages/registrationPage/registrationPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".registrationPage {\n    display: flex;\n    flex-flow: column;\n    justify-content: space-evenly;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registrationPage": "registrationPage_registrationPage__wGii4"
};
export default ___CSS_LOADER_EXPORT___;
