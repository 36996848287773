import { useState } from "react";
import InputField from "../inputField/InputField";
import AnotherLoginMethodIcon from "../iconsAndImages/anotherLoginMethodIcon/AnotherLoginMethodIcon";
import Select from "../select/Select";
import styles from "./registrationForm.module.css"
import googleIcon from "../../img/google-login-icon.svg";
import xidIcon from "../../img/xid-login-icon.svg";
import { Link } from "react-router-dom";

const RegistrationForm = ({ initData, onSubmit }) => {
    const [registrationData, setRegistrationData] = useState(initData);

    const selectOptions = [
        { value: "Соискатель" },
        { value: "Представитель организации" },
        { value: "Преподаватель" }
    ];

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(registrationData);
    };

    const handleChange = fieldName => fieldValue => {
        setRegistrationData({ ...registrationData, [fieldName]: fieldValue })
    }

    return (
        <form className={styles.form} onSubmit={handleSubmit}>

            <h1 className={styles.formHeader}>Регистрация в Workograd</h1>

            <div className={styles.formContent}>

                <div className={styles.personalInfoBlock}>
                    <InputField
                        type={"text"} label={"Фамилия*"} placeholder={"Введите фамилию"}
                        value={registrationData.lastName}
                        required={true}
                        pattern={"^[A-Za-zА-Яа-я]{2,}$"}
                        invalidInputMessage={"Фамилия должна содержать не менее двух букв и не содержать цифр"}
                        onChange={handleChange("lastName")}
                    />
                    <div className={styles.firstAndAdditionalNamesBlock}>
                        <InputField
                            type={"text"} label={"Имя*"} placeholder={"Введите имя"}
                            value={registrationData.firstName}
                            required={true}
                            pattern={"^[A-Za-zА-Яа-я]{2,}$"}
                            invalidInputMessage={"Имя должно содержать не менее двух букв и не содержать цифр"}
                            onChange={handleChange("firstName")}
                        />
                        <InputField
                            type={"text"} label={"Отчество"} placeholder={"Введите отчество"}
                            value={registrationData.additionalName}
                            pattern={"^[A-Za-zА-Яа-я]{2,}$"}
                            invalidInputMessage={"Отчество должно содержать не менее двух букв и не содержать цифр"}
                            onChange={handleChange("additionalName")}
                        />
                    </div>
                    <InputField
                        type={"tel"} label={"Телефон"} placeholder={"Введите телефон"}
                        value={registrationData.phoneNumber}
                        pattern={`^(\\+7\\([0-9]{3}\\)-[0-9]{3}-[0-9]{2}-[0-9]{2})|(\\+7)$`}
                        invalidInputMessage={"Телефон заполнен неверно"}
                        onChange={handleChange("phoneNumber")}
                    />
                    <div className={styles.selectBlock}>
                        <label className={styles.selectBlockLabel}>Тип аккаунта*</label>
                        <br />
                        <Select
                            options={selectOptions}
                            value={registrationData.accountType}
                            pattern={`^((?!${initData.accountType}).)*$`}
                            invalidInputMessage={"Выберите тип аккаунта"}
                            onChange={handleChange("accountType")}
                        />
                    </div>
                    <div className={styles.agreementSpanBlock}>
                        <span className={styles.agreementSpan}>Нажимая кнопку "Зарегистрироваться", вы соглашаетесь с политикой конфиденциальности сайта
                        </span>
                    </div>
                </div>

                <div className={styles.userInfoBlock}>
                    <InputField
                        type={"text"} label={"Email*"} placeholder={"Введите email"}
                        value={registrationData.email}
                        required={true}
                        pattern={`^(([^<>[\]\\.,;:\s@\"]+(\.[^<>[\]\\.,;:\s@\"]+)*)|(\".+\"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$`}
                        invalidInputMessage={"Неверный формат email"}
                        onChange={handleChange("email")}
                    />
                    <InputField
                        type={"password"} label={"Пароль*"} placeholder={"Введите пароль"}
                        value={registrationData.password}
                        required={true}
                        pattern={`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}`}
                        invalidInputMessage={"Не менее восьми латинских букв и цифр, не менее одной цифры, одной заглавной и одной строчной буквы"}
                        onChange={handleChange("password")}
                    />
                    <InputField
                        type={"password"} label={"Повторите пароль*"} placeholder={"Введите пароль"}
                        value={registrationData.passwordConfirm}
                        required={true}
                        pattern={registrationData.password}
                        invalidInputMessage={"Пароли не совпадают"}
                        onChange={handleChange("passwordConfirm")}
                    />

                    <button
                        className={styles.submitButton}
                        type={"submit"}
                    >
                        Зарегистрироваться
                    </button>

                    <div className={styles.othersLoginMethodsContainer}>
                        <div className={styles.anotherLoginMethodsSpanBlock}>
                            <span className={styles.anotherLoginMethodsSpan}>Или войти с помощью:</span>
                        </div>
                        <AnotherLoginMethodIcon src={googleIcon} alter={"google"} />
                        <AnotherLoginMethodIcon src={xidIcon} alter={"xid"} />
                        <AnotherLoginMethodIcon src={xidIcon} alter={"xid"} />
                    </div>

                    <Link className={styles.link} to={`../login`}>
                        <div className={styles.toLoginPageSpanBlock}>
                            <span className={styles.toLoginPageSpan}>Уже есть аккаунт? </span>
                            <span className={styles.toLoginPageSpanUnderline}>Войти</span>
                        </div>
                    </Link>
                </div>

            </div>

        </form>
    );
}

export default RegistrationForm;