// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".profileFieldsSocialMediaField_container__LXJwe {\n    margin-top: 2.5em;\n}\n\n.profileFieldsSocialMediaField_label__\\+k-OP {\n    padding-left: .5em;\n    color: #A5D7E2;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n}\n\n.profileFieldsSocialMediaField_content__XaWt1 {\n    display: flex;\n    justify-content: space-between;\n}\n\n.profileFieldsSocialMediaField_socialMediaList__cCH9s {\n    padding-left: .5em;\n    color: #36899B;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n}\n\n.profileFieldsSocialMediaField_addSocialMedia__RjeLJ {\n    background-color: white;\n    border: none;\n\n    font-size: 1rem;\n    font-weight: 600;\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    text-decoration: underline;\n\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/components/profileContent/profileFieldsBlock/profileFieldsSocialMediaField/profileFieldsSocialMediaField.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,+BAA+B;IAC/B,eAAe;AACnB;;AAEA;IACI,uBAAuB;IACvB,YAAY;;IAEZ,eAAe;IACf,gBAAgB;IAChB,+BAA+B;IAC/B,cAAc;IACd,0BAA0B;;IAE1B,eAAe;AACnB","sourcesContent":[".container {\n    margin-top: 2.5em;\n}\n\n.label {\n    padding-left: .5em;\n    color: #A5D7E2;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n}\n\n.content {\n    display: flex;\n    justify-content: space-between;\n}\n\n.socialMediaList {\n    padding-left: .5em;\n    color: #36899B;\n    font-family: Roboto, sans-serif;\n    font-size: 1rem;\n}\n\n.addSocialMedia {\n    background-color: white;\n    border: none;\n\n    font-size: 1rem;\n    font-weight: 600;\n    font-family: Roboto, sans-serif;\n    color: #36899B;\n    text-decoration: underline;\n\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "profileFieldsSocialMediaField_container__LXJwe",
	"label": "profileFieldsSocialMediaField_label__+k-OP",
	"content": "profileFieldsSocialMediaField_content__XaWt1",
	"socialMediaList": "profileFieldsSocialMediaField_socialMediaList__cCH9s",
	"addSocialMedia": "profileFieldsSocialMediaField_addSocialMedia__RjeLJ"
};
export default ___CSS_LOADER_EXPORT___;
