import { useState } from "react";
import InputField from "../inputField/InputField";
import AnotherLoginMethodIcon from "../iconsAndImages/anotherLoginMethodIcon/AnotherLoginMethodIcon";
import styles from "./loginForm.module.css";
import googleIcon from "../../img/google-login-icon.svg";
import xidIcon from "../../img/xid-login-icon.svg";
import { Link } from "react-router-dom";

const LoginForm = ({ initData, onSubmit }) => {
    const [loginData, setLoginData] = useState(initData);

    const handleSubmit = e => {
        e.preventDefault();
        onSubmit(loginData);
    };

    const handleChange = fieldName => fieldValue => {
        setLoginData({ ...loginData, [fieldName]: fieldValue })
    }

    return (
        <form className={styles.form} onSubmit={handleSubmit} action={"some url"}>

            <h1 className={styles.formHeader}>Вход в Workograd</h1>

            <div className={styles.formContent}>

                <InputField
                    type={"text"} label={"Email"} placeholder={"Введите email"}
                    value={loginData.email}
                    required={true}
                    pattern={`^(([^<>[\]\\.,;:\s@\"]+(\.[^<>[\]\\.,;:\s@\"]+)*)|(\".+\"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))$`}
                    invalidInputMessage={"Неверный формат email"}
                    onChange={handleChange("email")}
                />
                <InputField
                    type={"password"} label={"Пароль"} placeholder={"Введите пароль"}
                    value={loginData.password}
                    required={true}
                    pattern={"^.+$"}
                    invalidInputMessage={""}
                    onChange={handleChange("password")}
                />
                <button
                    className={styles.submitButton}
                    type={"submit"}
                >
                    Войти
                </button>
                <div className={styles.othersLoginMethodsContainer}>
                    <div className={styles.anotherLoginMethodsSpanBlock}>
                        <span className={styles.anotherLoginMethodsSpan}>Или войти с помощью:</span>
                    </div>
                    <AnotherLoginMethodIcon src={googleIcon} alter={"google"} />
                    <AnotherLoginMethodIcon src={xidIcon} alter={"xid"} />
                    <AnotherLoginMethodIcon src={xidIcon} alter={"xid"} />
                </div>

                <Link className={styles.link} to={`../registration`}>
                    <div className={styles.toRegistrationPageSpanBlock}>
                        <span className={styles.toRegistrationPageSpan}>Еще нет аккаунта? </span>
                        <span className={styles.toRegistrationSpanUnderline}>Зарегестрироваться</span>
                    </div>
                </Link>

            </div>

        </form>
    );
}

export default LoginForm;