import styles from "./educationItem.module.css";
import InputField from "../../../../../components/inputField/InputField";
import {useState} from "react";
import DeleteButton from "../../../../../components/deleteButton/DeleteButton";

const EducationItem = ({initData, onChange, onDelete, onSave}) => {

    const [educationData, setEducationData] = useState(initData);

    const handleChange = fieldName => fieldValue => {
        const newEducationData = {...educationData, [fieldName]: fieldValue};
        if (newEducationData.status === "existed") newEducationData.status = "edited";
        setEducationData(newEducationData);
        onChange(newEducationData);
    }

    const handleDelete = () => {
        onDelete(educationData);
    }

    const handleSubmit = e => {
        e.preventDefault();
        onSave();
    }
    return (
        <form className={styles.form} onSubmit={handleSubmit} id="Education">
            <div className={styles.educationBlock}>
                <div className={styles.inputsBlock}>
                    <div className={styles.flexGrow3}>
                        <InputField
                            type={"text"} label={"Вуз*"} placeholder={"Введите название учебного учреждения"}
                            value={educationData.universityName}
                            required={true}
                            //pattern={"^[A-Za-zА-Яа-я0-9 ]{1,}$"}
                            invalidInputMessage={"Назавние Вуза не может быть пустым"}
                            onChange={handleChange("universityName")}
                        />
                    </div>
                    <div className={styles.flexGrow1}>
                        <InputField
                            type={"text"} label={"Уровень образования*"} placeholder={"Введите уровень образования"}
                            value={educationData.universityDegree}
                            required={true}
                            //pattern={"^[A-Za-zА-Яа-я0-9 ]{1,}$"}
                            invalidInputMessage={"Уровень образования не может быть пустым"}
                            onChange={handleChange("universityDegree")}
                        />
                    </div>
                    <div className={styles.flexGrow1}>
                        <InputField
                            type={"number"} label={"Год окончания*"} placeholder={"Введите год окончания"}
                            value={educationData.graduationYear}
                            required={true}
                            //pattern={"^[0-9]{4,}$"}
                            invalidInputMessage={"Год окончания не может быть пустым и должен иметь 4 цифры"}
                            onChange={handleChange("graduationYear")}
                        />
                    </div>
                </div>
                <div className={styles.inputsBlock}>
                    <div className={styles.flexGrow3}>
                        <InputField
                            type={"text"} label={"Направление*"} placeholder={"Введите направление обучения"}
                            value={educationData.educationFaculty}
                            required={true}
                            //pattern={"^[A-Za-zА-Яа-я0-9 ]{1,}$"}
                            invalidInputMessage={"Направление не может быть пустым"}
                            onChange={handleChange("educationFaculty")}
                        />
                    </div>
                    <div className={styles.flexGrow3}>
                        <InputField
                            type={"text"} label={"Профиль*"} placeholder={"Введите профиль обучения"}
                            value={educationData.educationProfile}
                            required={true}
                            //pattern={"^[A-Za-zА-Яа-я0-9 ]{1,}$"}
                            invalidInputMessage={"Профиль не может быть пустым"}
                            onChange={handleChange("educationProfile")}
                        />
                    </div>
                    <div className={styles.flexGrow1}>
                        <InputField
                            type={"number"} label={"Средний балл"} placeholder={"Укажите средний балл диплома"}
                            min="0"
                            max="5"
                            step=".01"
                            value={educationData.averageGrade}
                            required={false}
                            //pattern={"^[0-4]{1}[\.]{1}[0-9]{0,2}|[5]{1}|[4]{1}|[3]{1}$"}
                            invalidInputMessage={"Средний балл не должен превышать 5 и 2-x знаков после запятой"}
                            onChange={handleChange("averageGrade")}
                        />
                    </div>
                </div>
                <div className={styles.deleteButtonBlock}>
                    <DeleteButton
                        buttonText={"Удалить запись об образовании"}
                        onDelete={handleDelete}
                    />
                </div>
                
            </div>
        </form>
    );
}

export default EducationItem;



