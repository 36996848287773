import styles from "./changePasswordPopup.module.css"
import InputField from "../../../../inputField/InputField";
import {useState} from "react";

const ChangePasswordPopup = ({onCancel}) => {
    const initPasswordData = {
        oldPassword: "",
        password: "",
        confirmPassword: "",
    }
    const handleChange = fieldName => fieldValue => {
        setPasswordData({...passwordData, [fieldName]: fieldValue})
    }
    const [passwordData, setPasswordData] = useState(initPasswordData)

    return(
        <div className={styles.container}>
            <div onClick={onCancel} className={styles.overlay}/>
            <form className={styles.form}>
                <h1 className={styles.header}>Изменение пароля</h1>
                <InputField
                    type={"password"} label={"Старый пароль"} placeholder={"Введите текущий пароль"}
                    value={passwordData.oldPassword}
                    required={true}
                    pattern={`^.+$`}
                    invalidInputMessage={""}
                    onChange={handleChange("oldPassword")}
                />
                <InputField
                    type={"password"} label={"Новый пароль"} placeholder={"Введите пароль"}
                    value={passwordData.password}
                    required={true}
                    pattern={`^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,}`}
                    invalidInputMessage={"Не менее восьми латинских букв и цифр, не менее одной цифры, одной заглавной и одной строчной буквы"}
                    onChange={handleChange("password")}
                />
                <InputField
                    type={"password"} label={"Повторите новый пароль"} placeholder={"Введите пароль"}
                    value={passwordData.passwordConfirm}
                    required={true}
                    pattern={passwordData.password}
                    invalidInputMessage={"Пароли не совпадают"}
                    onChange={handleChange("passwordConfirm")}
                />
                <button
                    className={styles.submitButton}
                    type={"submit"}
                >
                    Сохранить
                </button>
            </form>
        </div>
    )
}

export default ChangePasswordPopup;