import styles from "./profileFieldsPasswordField.module.css"
import ChangePasswordPopup from "./changePasswordPopup/ChangePasswordPopup";
import {useState} from "react";

const ProfileFieldsPasswordField = () => {

    const [popupShow, setPopupShow] =useState(false);

    const togglePopup = () => {
        console.log(popupShow);
        setPopupShow(!popupShow);
    }

    return (
        <div className={styles.container}>
            <span className={styles.label}>Пароль</span>
            <div className={styles.content}>
                <span className={styles.passwordStatus}>Установлен</span>
                <button className={styles.changePassword} onClick={togglePopup}>Изменить</button>
            </div>
            {popupShow && <ChangePasswordPopup onCancel={togglePopup}/>}
        </div>
    )
}

export default ProfileFieldsPasswordField;