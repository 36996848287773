import styles from "./vacancyDescription.module.css";
import TextareaInput from "../../../../components/textarea/TextareaInput";

const VacancyDescription = ({vacancyDescription, onVacancyDescriptionChange}) => {

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.inputsBlock}>
                    <TextareaInput
                        label={"Описание вакансии"}
                        placeholder={"Опишите, что вы ждете от потенциального кандидата на дожность"}
                        value={vacancyDescription}
                        onChange={onVacancyDescriptionChange}
                    />
                </div>
            </div>
        </div>
    )
};

export default VacancyDescription;