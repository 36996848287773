// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.resumePage_container__95CLA {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-size: 1rem;\n}\n\n.resumePage_content__6yh8Q {\n    width: 60%;\n}", "",{"version":3,"sources":["webpack://./src/pages/resumePage/resumePage.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,UAAU;AACd","sourcesContent":["\n.container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-size: 1rem;\n}\n\n.content {\n    width: 60%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "resumePage_container__95CLA",
	"content": "resumePage_content__6yh8Q"
};
export default ___CSS_LOADER_EXPORT___;
