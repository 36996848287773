// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.vacancySalary_container__7QUrH {\n    padding-bottom: 1.5rem;\n}\n\n.vacancySalary_content__UYR38 {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.vacancySalary_inputsBlock__4dd\\+J {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 100%;\n    margin-bottom: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/vacancyPublishPage/components/vacancySalary/vacancySalary.module.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,WAAW;IACX,mBAAmB;AACvB","sourcesContent":["\n.container {\n    padding-bottom: 1.5rem;\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.inputsBlock {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 100%;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vacancySalary_container__7QUrH",
	"content": "vacancySalary_content__UYR38",
	"inputsBlock": "vacancySalary_inputsBlock__4dd+J"
};
export default ___CSS_LOADER_EXPORT___;
