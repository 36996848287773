import styles from "./playground.module.css"
import CompetenciesBlock from "../competenciesBlock/CompetenciesBlock";
import { useState } from "react";
import { Link } from 'react-router-dom'

// Only for trying some stuff!
const Playground = () => {

    const contentStr = 'Это статья, в которой мы добавили <ссылку на эту же самую статью>, а также <ссылку на статью "пока">';

    return (
        <div className={styles.container}>
            <div className={styles.container}>
                {"Страница для быстрого тестирования идей"}
            </div>
        </div>
    )
}

export default Playground;