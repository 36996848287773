import styles from "./loginPage.module.css"
import WelcomeImageBlock from "../../components/iconsAndImages/welcomeImage/WelcomeImageBlock";
import LoginForm from "../../components/loginForm/LoginForm";
import FooterImage from "../../components/iconsAndImages/footerImage/FooterImage";
import axios from "axios"
import {LoginFormInitData} from "../../utils/constants";
import {loginUrl} from "../../utils/apiUrls";

const LoginPage = () => {

    const onFormSubmit = formData => {
        axios.post(loginUrl, formData, {
            withCredentials: true,
        }).then(value => {
            localStorage.setItem("authorizationToken", value.data.token);
            localStorage.setItem("type_user", value.data.type_user);
            if (value.status === 200) {
                window.location.href = '/profile';
            } else {
                console.error("error while login form submit. request status code: " + value.status);
            }
        })
    }

    return(
        <div className={styles.loginPage}>
            <WelcomeImageBlock />

            <LoginForm initData={LoginFormInitData} onSubmit={onFormSubmit}/>

            <FooterImage />
        </div>
    )

}

export default LoginPage;